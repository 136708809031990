import React, { useContext, useEffect, useState } from "react";
import Assistance from "../components/Assistance";
import laptopBoi from "../assets/images/laptop-boi.png";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const ThankYou = () => {
  const { url, user, getToken, getAccountStatus } = useContext(AppContext);
  const navigate = useNavigate("");
  const [loading, setLoading] = useState(false);
  const [laterDate, setLaterDate] = useState("");
  const [laterStatus, setLaterStatus] = useState(0);
  const [payNow, setPayNow] = useState(false);
  //
  const checkPayment = async (id, token) => {
    try {
      const res = await axios.get(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Invoice_Payment/search?criteria=((Payment_Number:equals:1)and(Status:equals:paid)and(Lead_Name:equals:${id}))`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status !== 204) {
        if (
          res.data.data[0].Status.toLowerCase() === "paid" &&
          res.data.data[0].Enroll_Payment_Status.toLowerCase() === "paid" &&
          res.data.data[0].Step === 3
        ) {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const paymentAuth = async () => {
    try {
      setLoading(true);
      let token = await getToken();
      const sdUser = localStorage.getItem("sdUser");
      if (sdUser) {
        const res = await axios.get(
          `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/search?criteria=((Phone_Number:equals:${JSON.parse(
            sdUser
          )}))`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          }
        );
        checkPayment(res.data.data[0].id, token);
        if (res.data.data[0].Enroll_Payment_Status.toLowerCase() !== "paid") {
          navigate("/enroll", { replace: true });
        } else {
          const details = res.data.data[0];
          for (const key in details) {
            if (details[key] === null) {
              details[key] = "";
            }
          }
          if (details.Promise_to_Pay_Date !== "") {
            setLaterDate(details.Promise_to_Pay_Date);
            setLaterStatus(2);
            const date = new Date();
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const day = String(date.getDate()).padStart(2, "0");
            const formattedDate = `${year}-${month}-${day}`;
            if (formattedDate == details.Promise_to_Pay_Date) {
              setPayNow(true);
            } else {
              setPayNow(false);
            }
          } else {
            setLaterStatus(0);
          }
        }
      } else {
        navigate("/login", { replace: true });
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    paymentAuth();
  }, []);
  //
  const successToast = (message) => toast.success(message);
  const errorToast = (message) => toast.error(message);
  //
  const createOrder = async () => {
    const sdUser = JSON.parse(localStorage.getItem("sdUser"));
    const result = await getAccountStatus(sdUser);

    if (result !== null) {
      try {
        const { data } = await axios.post(`${url}/create-order`, {
          amount: result.Total_Plan_Amount,
        });
        const options = {
          key: "rzp_live_rTeC0Xl72J36OB",
          amount: data.amount,
          currency: data.currency,
          name: user.Full_Name,
          description: "First payment",
          order_id: data.id,
          handler: async function (response) {
            const verifyRes = await axios.post(`${url}/verify-payment`, {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            if (verifyRes.data.success) {
              verifyPayment(verifyRes.status, result);
              successToast("Payment done successfully!");
            } else {
              errorToast("Payment verification failed");
            }
          },
          prefill: {
            name: user.Full_Name,
            email: user.Email,
            contact: user.Phone_Number,
          },
          theme: {
            color: "#ff4865",
          },
        };
        const razor = new window.Razorpay(options);
        razor.open();
      } catch (error) {
        console.log(error);
      }
    }
  };
  const verifyPayment = async (status, result) => {
    if (status === 200) {
      const date = new Date();
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      //
      const futureDate = new Date(date);
      futureDate.setDate(date.getDate() + 5);
      const futureYear = futureDate.getFullYear();
      const futureMonth = String(futureDate.getMonth() + 1).padStart(2, "0");
      const futureDay = String(futureDate.getDate()).padStart(2, "0");
      const formattedFutureDate = `${futureYear}-${futureMonth}-${futureDay}`;
      //
      let randomNumber = [];
      for (let i = 1; i <= 4; i++) {
        const generateNumber = Math.floor(Math.random() * 10);
        randomNumber.push(generateNumber);
      }
      //
      const data = [
        {
          Due_Date: formattedFutureDate,
          Invoice_Date: formattedDate,
          Name: `INV-PORTAL-${randomNumber.join("")}`,
          Payment_Date: formattedDate,
          Payment_Number: "1",
          Status: "Paid",
          Payment_Mode: "Razorpay-Portal",
          //
          Lead_Name: result.id,
          Management_Fees_and_Tax: result.Subscription_Fees,
          Creditors_Amount: result.Monthly_EMI_Payment,
          Sub_Total: result.Monthly_EMI_Payment,
          Grand_Total: result.Total_Plan_Amount,
          Payment_Made: result.Total_Plan_Amount,
        },
      ];
      const token = await getToken();
      const res = await axios.post(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Invoice_Payment`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status === 201) {
        successToast("Payment done successfully!");
        setTimeout(() => {
          navigate("/creditors", { replace: true });
        }, 3000);
      } else {
        errorToast("Something went wrong.");
      }
    } else if (status === 400) {
      errorToast("Payment was cancelled.");
    }
  };
  //
  const [payLater, setPaylater] = useState({
    salaryDate: "",
    paymentDate: "",
  });
  const [payLaterErrors, setPaylaterErrors] = useState({
    salaryDate: "",
    paymentDate: "",
  });
  const handlePayLater = (e) => {
    const { name, value } = e.target;
    setPaylater({
      ...payLater,
      [name]: value,
    });
    setPaylaterErrors({
      ...payLaterErrors,
      [name]: "",
    });
  };
  //
  const cancelPayLater = () => {
    setLaterStatus(0);
    setPaylater({
      salaryDate: "",
      paymentDate: "",
    });
    setPaylaterErrors({
      salaryDate: "",
      paymentDate: "",
    });
  };
  //
  const validate = () => {
    const { salaryDate, paymentDate } = payLater;
    const newErrors = {};

    if (!salaryDate) {
      newErrors.salaryDate = "Enter your salary date";
    }
    if (!paymentDate) {
      newErrors.paymentDate = "Enter your payment date";
    }

    setPaylaterErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSubmit();
    }
  };

  const handleSubmit = async () => {
    try {
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      const result = await getAccountStatus(sdUser);
      //
      const data = [
        {
          Next_salary_payment_date: payLater.salaryDate,
          Promise_to_Pay_Date: payLater.paymentDate,
        },
      ];
      const token = await getToken();
      const res = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${result.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status === 200) {
        successToast("Data saved");
        paymentAuth();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="container p-2">
        <Assistance body={"Thank you for enrolling with SingleDebt"} />
      </div>
      <div className="assistance-seperator"></div>
      <div className="payment-success pb-5 px-2">
        <div className="laptop-boi">
          <img src={laptopBoi} alt="" />
        </div>

        <div className="mt-4">
          <div className="text-center">
            <h1 className="mb-4">🎉 Exciting update! Enrollment Confirmed.</h1>
            <p>
              Thank you for enrolling! To activate your account and initiate
              payments to your creditors, please complete your first EMI payment
              at your earliest convenience.
            </p>
          </div>

          {laterStatus === 0 ? (
            <div className="d-flex align-items-center justify-content-center gap-2 my-4">
              <button className="button" onClick={createOrder}>
                Pay Now
              </button>
              <button className="button" onClick={() => setLaterStatus(1)}>
                Pay Later
              </button>
            </div>
          ) : (
            ""
          )}
          {laterStatus === 1 ? (
            <div className="pay-later-div my-4">
              <div className="mb-3">
                <div className="form-control">
                  <input
                    type="date"
                    name="salaryDate"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={payLater.salaryDate}
                    onChange={handlePayLater}
                    min={new Date().toISOString().split("T")[0]}
                  />
                  <label
                    htmlFor="salaryDate"
                    className="form-label input-label"
                  >
                    Salary date
                  </label>
                </div>
                <p className="text-danger">{payLaterErrors.salaryDate}</p>
              </div>
              <div className="mb-3">
                <div className="form-control">
                  <input
                    type="date"
                    name="paymentDate"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={payLater.paymentDate}
                    onChange={handlePayLater}
                    min={new Date().toISOString().split("T")[0]}
                  />
                  <label
                    htmlFor="paymentDate"
                    className="form-label input-label"
                  >
                    Payment date
                  </label>
                </div>
                <p className="text-danger">{payLaterErrors.paymentDate}</p>
              </div>
              <div className="d-flex align-items-center justify-content-center gap-2">
                <button className="white-button" onClick={cancelPayLater}>
                  Cancel
                </button>
                <button className="button" onClick={validate}>
                  Submit
                </button>
              </div>
            </div>
          ) : (
            ""
          )}

          {laterStatus === 2 ? (
            <div className="text-center my-4">
              {payNow ? (
                <></>
              ) : (
                <>
                  <p>
                    You can make the payment on{" "}
                    <span className="fw-bold">
                      {laterDate !== "" &&
                        laterDate.split("-").reverse().join("-")}
                    </span>
                    .
                  </p>
                  <p>
                    However, if you still want to make the payment now{" "}
                    <span
                      className="fw-fw-bolder primary-text"
                      onClick={createOrder}
                      style={{ cursor: "pointer" }}
                    >
                      Click here
                    </span>
                  </p>
                </>
              )}
              <button
                className={`${
                  payNow === false ? "disabled-button" : ""
                } button mt-2`}
                disabled={payNow}
              >
                Pay your first EMI
              </button>
            </div>
          ) : (
            ""
          )}

          <div className="text-center">
            <p>
              This link is valid for 12 hours, so please complete your setup
              before it expires. <br />
              <br />
              If you have any questions, we're here to help! Call us at{" "}
              <a href="tel:022 68762605" className="primary-text fw-bold">
                022 68762605
              </a>{" "}
              between 9 AM and 7 PM, Monday to Saturday, or access live support.
            </p>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" autoClose={2000} theme="light" />
    </>
  );
};

export default ThankYou;
