import React, { useContext, useEffect, useState } from "react";
import EnrollIntroduction from "../components/Enroll/EnrollIntroduction";
import EnrollForm1 from "../components/Enroll/EnrollForm1";
import Plans from "../components/Enroll/Plans";
import Payment from "../components/Enroll/Payment";
import { AppContext } from "../context/AppContext";
import Loading from "../components/Loading";
import Navbar from "../components/Navbar";
import { useNavigate } from "react-router-dom";

const Enroll = () => {
  const { getAccountStatus } = useContext(AppContext);
  const [step, setStep] = useState(0);
  const [loading, setLoading] = useState(true);
  //
  const [inputs, setInputs] = useState({
    Outstanding: "",
    No_Of_Loans: "",
    EMI_Payments: "",
    Missed_Payment: "",
    Harassment_Type: "",
    Legal_Status: "",
    Income: "",
    Expenses: "",
  });
  const [errors, setErrors] = useState({
    Outstanding: "",
    No_Of_Loans: "",
    EMI_Payments: "",
    Missed_Payment: "",
    Harassment_Type: "",
    Legal_Status: "",
    Income: "",
    Expenses: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: "",
    });
  };
  //
  const components = [
    <EnrollIntroduction step={step} setStep={setStep} />,
    <EnrollForm1
      step={step}
      setStep={setStep}
      inputs={inputs}
      handleInputs={handleInputs}
      errors={errors}
      setErrors={setErrors}
    />,
    <Plans step={step} setStep={setStep} inputs={inputs} />,
    <Payment step={step} setStep={setStep} />,
  ];
  //
  useEffect(() => {
    const checkAccountCreated = async () => {
      const sdUser = localStorage.getItem("sdUser");
      if (!sdUser) {
        navigate("/login", { replace: true });
      }
      const result = await getAccountStatus(JSON.parse(sdUser));
      setInputs(result);
      console.log(result);
      if (result.id !== "") {
        //
        if (result.Step === 0) {
          setStep(0);
        } else if (result.Step === 1) {
          setStep(2);
        } else if (result.Step === 2) {
          setStep(3);
        } else if (result.Step === 3) {
          navigate("/thankyou", { replace: true });
        }
      }
    };
    checkAccountCreated();
    //
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);
  //
  const [modalVisible, setModalVisible] = useState(false);
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("sdUser");
    localStorage.removeItem("enrolled");
    navigate("/login", { replace: true });
  };
  return (
    <>
      <Navbar setModalVisible={setModalVisible} />
      <div className="container p-2">
        {loading ? <Loading /> : components[step]}
      </div>

      {/*  */}
      <div className={`custom-modal-container ${modalVisible ? "active" : ""}`}>
        <div
          className="custom-modal-background"
          onClick={() => setModalVisible(false)}
        ></div>
        <div className="custom-modal-box">
          <div className="body p-2 py-4 rounded text-center">
            <h1>Logout?</h1>
            <div className="d-flex align-items-center justify-content-center gap-2 mt-4">
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={logout}
              >
                Yes
              </button>
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={() => setModalVisible(false)}
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Enroll;
