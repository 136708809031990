import React, { useContext, useEffect, useState } from "react";
import { FaCheckCircle, FaRupeeSign } from "react-icons/fa";
import { AppContext } from "../../context/AppContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Assistance from "../Assistance";
import { useNavigate } from "react-router-dom";

const Payment = ({ step, setStep }) => {
  const { url, user, getAccountStatus, getToken } = useContext(AppContext);
  const navigate = useNavigate();
  const [result, setResult] = useState(null);
  useEffect(() => {
    const checkAccountCreated = async () => {
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      setResult(await getAccountStatus(sdUser));
    };
    checkAccountCreated();
  }, []);
  //
  const successToast = (message) => toast.success(message);
  const errorToast = (message) => toast.error(message);
  const [buttonDisable, setButtonDisable] = useState(false);
  //
  const createOrder = async () => {
    const sdUser = JSON.parse(localStorage.getItem("sdUser"));
    const result = await getAccountStatus(sdUser);

    if (result !== null) {
      try {
        const { data } = await axios.post(`${url}/create-order`, {
          amount: 599,
        });
        const options = {
          key: "rzp_live_rTeC0Xl72J36OB",
          amount: data.amount,
          currency: data.currency,
          name: user.Full_Name,
          description: "Enrollment payment",
          order_id: data.id,
          handler: async function (response) {
            const verifyRes = await axios.post(`${url}/verify-payment`, {
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            });

            if (verifyRes.data.success) {
              verifyPayment(verifyRes.status, response.razorpay_payment_id);
              successToast("Payment done successfully!");
            } else {
              errorToast("Payment verification failed!");
            }
          },
          prefill: {
            name: user.Full_Name,
            email: user.Email,
            contact: user.Phone_Number,
          },
          theme: {
            color: "#ff4865",
          },
        };
        const razor = new window.Razorpay(options);
        razor.open();
      } catch (error) {
        console.log(error);
      }
    }
  };
  //
  const verifyPayment = async (status1, id) => {
    if (status1 === 200) {
      const date = new Date();

      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const formattedDate = `${year}-${month}-${day}`;
      const data = [
        {
          Enroll_Payment_Date: formattedDate,
          Enroll_Payment_Status: "Paid",
          Enroll_Payment_ID: id,
          Step: 3,
        },
      ];
      const token = await getToken();
      const res = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${result.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setButtonDisable(true);
        successToast("Payment done successfully!");
        setTimeout(() => {
          navigate("/thankyou", { replace: true });
        }, 1000);
      } else {
        errorToast("Something went wrong.");
      }
    } else if (status1 === 400) {
      errorToast("Payment was cancelled.");
    }
  };
  return (
    <>
      {result !== null ? (
        <>
          <Assistance body={"Your Harassment and Debt Solutions"} />
          <div className="assistance-seperator"></div>
          <div className="py-4">
            {result !== null ? (
              <>
                <div className="payment-list-items">
                  <h1 className="text-center">
                    Get started today for just <FaRupeeSign />
                    599
                  </h1>

                  <ul className="payment-list-items">
                    <li className="list-item-flex mb-3">
                      <FaCheckCircle />
                      Achieve debt freedom within{" "}
                      <span className="fw-bold">{result.Plan_Type}.</span>
                    </li>
                    {result.EMI_Payments - result.Total_Plan_Amount >= 0 ? (
                      <li className="list-item-flex mb-3">
                        <FaCheckCircle />
                        <span>
                          Enjoy affordable EMI's - pay only{" "}
                          <FaRupeeSign className="text-black" />
                          {parseFloat(result.Total_Plan_Amount).toLocaleString(
                            "en-IN"
                          )}{" "}
                          per month, saving{" "}
                          <FaRupeeSign className="text-black" />
                          {(
                            parseFloat(result.EMI_Payments) -
                            parseFloat(result.Total_Plan_Amount)
                          ).toLocaleString("en-IN")}{" "}
                          compared to your original EMI payments.
                        </span>
                      </li>
                    ) : (
                      ""
                    )}

                    <li className="list-item-flex mb-3">
                      <FaCheckCircle />
                      Say goodbye to creditor harassment and regain peace of
                      mind.
                    </li>
                    <li className="list-item-flex mb-3">
                      <FaCheckCircle />
                      Start improving your credit score and be ready to access
                      future loans.
                    </li>
                  </ul>
                </div>

                <div className="payment-details-container">
                  <div className="payment-details-box">
                    <h2>Selected Plan</h2>
                    <h2>{result.Plan_Type}</h2>
                  </div>
                  <div className="payment-details-box my-4">
                    <h2>Total monthly EMI</h2>
                    <h2>
                      <FaRupeeSign />
                      {parseFloat(result.Total_Plan_Amount).toLocaleString(
                        "en-IN"
                      )}
                    </h2>
                  </div>
                  <div className="payment-details-box">
                    <h2>Enrollment fees</h2>
                    <h2>
                      <FaRupeeSign />
                      599
                    </h2>
                  </div>
                  <div className="text-center mt-4">
                    <div className="enroll-button-flex">
                      <button
                        className="button payment-details-button"
                        onClick={() => setStep(step - 1)}
                        id="back-to-plan"
                      >
                        Back
                      </button>
                      <button
                        className={`button payment-details-button ${
                          buttonDisable ? "disabled-button" : ""
                        }`}
                        onClick={createOrder}
                        disabled={buttonDisable === true ? true : false}
                        id="enroll-now-button"
                      >
                        Enroll now for just <FaRupeeSign />
                        599
                      </button>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
          <ToastContainer position="top-right" autoClose={2000} theme="light" />
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default Payment;
