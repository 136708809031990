import React, { useContext, useEffect, useState } from "react";
import lady from "../assets/images/Lady.png";
import whiteLogo from "../assets/images/white logo.png";
import { Link, useNavigate } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";
import emi from "../assets/images/signin-icons/emi.png";
import harassment from "../assets/images/signin-icons/harassment.png";
import legal from "../assets/images/signin-icons/legal.png";
import creditscore from "../assets/images/signin-icons/creditscore.png";
import Apple from "../assets/images/signin-icons/Apple.png";
import Google from "../assets/images/signin-icons/Google.png";

const Signup2 = () => {
  const { url, getAccountStatus, getToken } = useContext(AppContext);
  const [generated, setGenerated] = useState(false);
  //
  const [inputs, setInputs] = useState({
    mobile: "",
    otp: "",
    details: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  //
  const navigate = useNavigate();

  //
  const generateOTP = async () => {
    if (!inputs.mobile) {
      setError(true);
      setMessage("Enter your mobile number");
    } else {
      try {
        setLoading(true);
        const res = await axios.get(
          `https://msg.mtalkz.com/V2/http-api-sms.php?apikey=ZwNEGnllw1d6psrt&senderid=SGLDBT&number=${inputs.mobile}&message=Your%20secret%20One%20Time%20Password%20(OTP)%20is%20{OTP}.%20Keep%20it%20confidential%20for%20security%20reasons%2C%20and%20don%27t%20share%20it%20with%20anyone.%20SingleDebt&format=json`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.Status === "Success") {
          setGenerated(true);
          setError(false);
          setInputs({
            ...inputs,
            details: res.data.Details,
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setError(false);
        setLoading(false);
      }
    }
  };
  const verifyOTP = async () => {
    if (!inputs.otp) {
      setError(true);
      setMessage("Enter OTP");
    } else {
      try {
        setGenerated(true);
        setLoading(true);
        const res = await axios.get(
          `https://msg.mtalkz.com/V2/http-verifysms-api.php?apikey=ZwNEGnllw1d6psrt&sessionid=${inputs.details}&otp=${inputs.otp}`,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (res.data.Status === "Success") {
          //
          const result = await getAccountStatus(inputs.mobile);

          if (result.Account_Status === "Inactive") {
            setError(true);
            setMessage(
              "Your account is not active. Please contact your account manager."
            );
          } else if (result.Account_Status === "Account not found") {
            setError(true);
            setMessage("Account not found. Please signup.");
            setTimeout(() => {
              navigate("/signup", { replace: true });
            }, 2000);
          } else if (result.Account_Status === "Enrolled") {
            setError(false);
            setMessage("Enrolled");
            localStorage.setItem("sdUser", JSON.stringify(inputs.mobile));
            setTimeout(() => {
              navigate("/enroll", { replace: true });
            }, 1000);
          } else if (
            result.Account_Status === "" ||
            result.Account_Status === null ||
            result.Account_Status === undefined
          ) {
            setError(true);
            setMessage("User not enrolled");
            const data = [
              {
                Account_Status: "Enrolled",
              },
            ];
            const token = await getToken();
            await axios.put(
              `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${result.id}`,
              data,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Zoho-oauthtoken ${token}`,
                },
              }
            );
            localStorage.setItem("sdUser", JSON.stringify(inputs.mobile));
            setTimeout(() => {
              navigate("/enroll", { replace: true });
            }, 2000);
          } else if (result.Account_Status === "Active") {
            setError(false);
            setMessage("Login successful");
            localStorage.setItem("sdUser", JSON.stringify(inputs.mobile));
            await getCreditorDebt(result.id);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);
  //
  const regenerateOTP = () => {
    setGenerated(false);
    setInputs({
      ...inputs,
      otp: "",
    });
  };
  //
  const getCreditorDebt = async (id) => {
    try {
      const token = await getToken();
      const res = await axios.get(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status === 200) {
        const creditorDebt = res.data.data[0].Creditors_Debt;
        creditorDebt.forEach((obj) => {
          for (const key in obj) {
            if (obj[key] === null) {
              obj[key] = "";
            }
          }
        });
        if (
          creditorDebt.length === 0 ||
          !creditorDebt ||
          creditorDebt === "" ||
          creditorDebt === null
        ) {
          navigate("/creditors", { replace: true });
        } else {
          navigate("/", { replace: true });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  //
  const wrongNumber = () => {
    setGenerated(false);
    setInputs({
      ...inputs,
      otp: "",
    });
  };
  return (
    <>
      <div className="signup2-container">
        <div className="lady-div">
          <img src={lady} alt="" />
        </div>
        <div className="content-div">
          <img src={whiteLogo} alt="" />
          <div className="my-4">
            <h2>Start Your Debt-Free Journey Today!</h2>
            <h1>Just Rs 599</h1>

            <div className="my-3">
              <p>
                Join <span className="fw-bold">SingleDebt</span> & take the
                first step toward <br />
                <span className="fw-bold">Financial Freedom</span>.
              </p>
            </div>

            <ul className="list-unstyled my-5">
              <li className="signin-icon-flex">
                {" "}
                <img src={emi} alt="" /> Affordable EMI Repayment Plan
              </li>
              <li className="signin-icon-flex">
                {" "}
                <img src={harassment} alt="" /> Harassment Relief
              </li>
              <li className="signin-icon-flex">
                {" "}
                <img src={legal} alt="" /> Legal Support
              </li>
              <li className="signin-icon-flex">
                {" "}
                <img src={creditscore} alt="" /> Credit Score Improvement
              </li>
            </ul>

            <div className="mb-3">
              <p>Be stress-free - Clear path to becoming debt-free.</p>
            </div>
          </div>
        </div>
        <div className="form-div">
          <div className="form-box">
            <div className="mb-4">
              <h1>Hello,</h1>
              <h2>Enter your details</h2>
            </div>

            <div>
              <div className="form-control">
                <input
                  type="tel"
                  name="mobile"
                  className="form-input"
                  placeholder="mobile"
                  required
                  disabled={generated ? true : false}
                  autoComplete="off"
                  value={inputs.mobile}
                  onChange={handleInputs}
                />
                <label htmlFor="mobile" className="form-label input-label">
                  mobile number
                </label>
              </div>
              {generated === true ? (
                <p
                  className="text-end mt-1 fw-bold mb-3"
                  style={{ cursor: "pointer" }}
                  onClick={wrongNumber}
                >
                  Wrong mobile number?
                </p>
              ) : (
                ""
              )}
              {generated === true ? (
                <div className="form-control mb-3">
                  <input
                    type="number"
                    name="otp"
                    className="form-input"
                    placeholder="OTP"
                    required
                    autoComplete="off"
                    value={inputs.otp}
                    onChange={handleInputs}
                  />
                  <label htmlFor="otp" className="form-label input-label">
                    OTP
                  </label>
                </div>
              ) : (
                ""
              )}
              <div className="mt-3">
                {message && (
                  <p
                    className={`${
                      error ? "text-danger" : "text-success"
                    } fw-bold`}
                  >
                    {message}
                  </p>
                )}
                <div className="text-center">
                  <button
                    className="white-button w-100"
                    style={{
                      justifyContent: loading ? "center" : "space-between",
                    }}
                    onClick={generated ? verifyOTP : generateOTP}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <>
                        {generated === false ? "Generate OTP" : "Login"}
                        <FaArrowRight className="ms-2" />
                      </>
                    )}
                  </button>
                  {generated === true ? (
                    <p
                      className="text-end mt-1 fw-bold"
                      style={{ cursor: "pointer" }}
                      onClick={regenerateOTP}
                    >
                      Regenerate OTP
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="text-center mt-3">
                <p>
                  Don't have an account?{" "}
                  <Link to="/signup" className="primary-text fw-bold">
                    Signup
                  </Link>
                </p>
              </div>

              <div className="store-flex mt-3">
                <a href="https://apps.apple.com/in/app/singledebt/id6480590793">
                  <img src={Apple} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.singledebt&hl=en_IN">
                  <img src={Google} alt="" />
                </a>
              </div>

              <ul className="tc-links mt-4">
                <li>
                  <Link to="/termsconditions">Terms & Conditions |</Link>
                </li>
                <li>
                  <Link
                    to="https://singledebt.in/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy |
                  </Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup2;
