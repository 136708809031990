import one from "../images/enroll-svg/1.png";
import two from "../images/enroll-svg/2.png";
import three from "../images/enroll-svg/3.png";
//
import debtSolutionPlan from "../images/description/debt_solution_plan.png";
import debtManagementPlan from "../images/description/debt_management_plan.png";
import ourFees from "../images/description/our_fees.png";
//
import boostCreditScore from "../images/description/boost_credit_score.png";
import futureLoans from "../images/description/our_fees.png";
import sheild from "../images/description/harassment_solutions.png";
import financialSupport from "../images/description/financial_support.png";
import realTimeTracking from "../images/description/debt_management_plan.png";
//
import emi from "../images/golden-box/emi.svg";
import harassment from "../images/golden-box/harassment.svg";
import creditScore from "../images/golden-box/credit-score.svg";
import debtFree from "../images/golden-box/debt-free.svg";

export const enrollIntroduction = [
  {
    img: one,
    title: "Our solutions are tailored to help you become debt-free while",
  },
  {
    img: two,
    title: "improving your credit score, enabling you to qualify for new loans",
  },
  {
    img: three,
    title: "after successfully completing the debt management program (DMP)",
  },
];

export const description = [
  {
    title: "Debt Solution Plan",
    body: "Our lawyers will negotiate with your creditors for one affordable EMI.",
    img: debtSolutionPlan,
  },
  {
    title: "Harassment Management Plan",
    body: "We'll handle all the calls for you, prevent creditors from contacting your friends and family, stop unannounced visits, and provide complete legal support, including responding to notices and attending hearings.",
    img: debtManagementPlan,
  },
  {
    title: "Our One Time Fees Rs 599",

    body: [
      {
        content: "One affordable EMI for all your unsecured loans",
        img: emi,
      },
      {
        content: "Relief Creditor Harassment",
        img: harassment,
      },
      {
        content: "Boost Your Credit Score",
        img: creditScore,
      },
      {
        content: "Become Debt Free",
        img: debtFree,
      },
    ],
    img: ourFees,
  },
];

export const offersData = [
  {
    title: "Boost Your Credit Score",
    body: "Enroll in our Debt Management Plan (DMP) to improve your credit score, without affecting your credit report like loan settlements do.",
    img: boostCreditScore,
  },
  {
    title: "Access to Future Loans",
    body: "Completing your DMP strengthens your credit profile, making you eligible for future loans.",
    img: futureLoans,
  },
  {
    title: "Shield from Creditor Harassment & Legal Protection",
    body: "We handle all creditor communications and legal matters, including harassment, notices, arbitration, and court representation, keeping you protected 24/7.",
    img: sheild,
  },
  {
    title: "Personalized Financial Support",
    body: "Get a dedicated relationship manager for customized budgeting and financial advice.",
    img: financialSupport,
  },
  {
    title: "Real-Time Tracking",
    body: "Track your payment progress in real time through our app and online portal.",
    img: realTimeTracking,
  },
];
