export const creditors = [
  {
    id: "532383000170417558",
    creditorName: "Unity Small Finance Bank Limited",
  },
  { id: "532383000170417001", creditorName: "Zap Money" },
  { id: "532383000169514694", creditorName: "Salary4sure" },
  { id: "532383000169329736", creditorName: "Instant Fund" },
  { id: "532383000169329335", creditorName: "Credit my cash" },
  { id: "532383000169329315", creditorName: "Borrower" },
  { id: "532383000169329295", creditorName: "Rupay" },
  { id: "532383000169329275", creditorName: "Insta Paise" },
  { id: "532383000169329255", creditorName: "Speedoloan" },
  { id: "532383000169329235", creditorName: "Flexi Funds" },
  { id: "532383000169329215", creditorName: "Wepay Loan" },
  { id: "532383000168690776", creditorName: "CredXpress" },
  { id: "532383000168690561", creditorName: "Urgent Rupee" },
  { id: "532383000168022473", creditorName: "Godrej Finance" },
  {
    id: "532383000166856281",
    creditorName: "Wes Ben Enterprises Private Limited",
  },
  { id: "532383000165019328", creditorName: "Ayaan Finserve India Pvt ltd" },
  { id: "532383000162858205", creditorName: "Taplend" },
  {
    id: "532383000162542122",
    creditorName: "South Canara District Cooperative",
  },
  { id: "532383000160682503", creditorName: "DIGI INDIA" },
  {
    id: "532383000158333643",
    creditorName: "DIGVIJAY VINIMAY PRIVATE LIMITED",
  },
  { id: "532383000157433210", creditorName: "Plebgraph" },
  { id: "532383000157421220", creditorName: "Gagan Metals Limited" },
  { id: "532383000155866134", creditorName: "money tap" },
  { id: "532383000155189372", creditorName: "Loanhub" },
  { id: "532383000155189352", creditorName: "SalaryKart" },
  { id: "532383000152825680", creditorName: "the catholic Syrian Bank Ltd" },
  { id: "532383000152825655", creditorName: "Mamta Projects Pvt Limited" },
  { id: "532383000152179521", creditorName: "RK Bansal Finance P Ltd" },
  { id: "532383000152179501", creditorName: "Mahashakti Financiers Ltd" },
  { id: "532383000151315083", creditorName: "Loanloom" },
  { id: "532383000151315063", creditorName: "Loandigit" },
  { id: "532383000149261595", creditorName: "CashMyPayment" },
  { id: "532383000148950470", creditorName: "Trust Paisa" },
  { id: "532383000148513831", creditorName: "VELICHAM FINANCE PVT LTD" },
  { id: "532383000148513671", creditorName: "CI Capital" },
  { id: "532383000145716706", creditorName: "Loan in Hand" },
  { id: "532383000145716658", creditorName: "Loan Finserve" },
  { id: "532383000143333076", creditorName: "Brahma Finance" },
  { id: "532383000143079814", creditorName: "ET Money" },
  { id: "532383000140818313", creditorName: "Esaf Small finance bank" },
  { id: "532383000135326402", creditorName: "Omozing" },
  { id: "532383000134727076", creditorName: "tvs Credit" },
  { id: "532383000132952482", creditorName: "Vizzie" },
  { id: "532383000132952464", creditorName: "Apna loan" },
  { id: "532383000132952287", creditorName: "Konark finserve" },
  { id: "532383000131498594", creditorName: "Catholic Syrian bank" },
  { id: "532383000131449062", creditorName: "EP Money" },
  { id: "532383000122199536", creditorName: "VRINDA FINLEASE LIMITED" },
  {
    id: "532383000122199518",
    creditorName: "Citra Financials Private limited",
  },
  { id: "532383000119779682", creditorName: "Creditfin" },
  { id: "532383000117107915", creditorName: "CFM" },
  { id: "532383000117107895", creditorName: "Creditwalle" },
  { id: "532383000116795206", creditorName: "Salary Loan" },
  { id: "532383000116795188", creditorName: "Bazaar Loan" },
  { id: "532383000116795046", creditorName: "Sabka Loan" },
  { id: "532383000116795026", creditorName: "Cash Plus Now" },
  { id: "532383000114805852", creditorName: "Salary on time" },
  { id: "532383000112609664", creditorName: "Open Capital" },
  { id: "532383000110650222", creditorName: "Housing.com" },
  { id: "532383000109902591", creditorName: "Khata Book" },
  {
    id: "532383000109780026",
    creditorName: "MY LOAN CARE VENTURES PRIVATE LIMITED",
  },
  { id: "532383000107316534", creditorName: "AGF FINLEASE (INDIA) LIMITED" },
  {
    id: "532383000101582465",
    creditorName: "Tycoon Credit & Portfolios Limited",
  },
  { id: "532383000101582348", creditorName: "Instant Funds" },
  {
    id: "532383000101577991",
    creditorName: "Tycoon Credit & Portfolios Limited",
  },
  {
    id: "532383000100491237",
    creditorName: "Encore Asset Reconstruction Company Pvt Ltd",
  },
  {
    id: "532383000100491219",
    creditorName: "RAMTIRTH LEASING AND FINANCE CO PVT LTD",
  },
  { id: "532383000098187898", creditorName: "Arthmate financing india" },
  { id: "532383000098002332", creditorName: "STFT" },
  { id: "532383000096317677", creditorName: "Goldline finance" },
  { id: "532383000093017258", creditorName: "Shaha Finlease." },
  { id: "532383000092964301", creditorName: "Click for Money" },
  { id: "532383000092964283", creditorName: "Loan For Care" },
  { id: "532383000092964265", creditorName: "Loan Within" },
  { id: "532383000092964247", creditorName: "Loan n Credit" },
  { id: "532383000092700511", creditorName: "Capital Rupee" },
  { id: "532383000087377799", creditorName: "Sayyam" },
  { id: "532383000087377773", creditorName: "RESPOFICAP" },
  { id: "532383000087212839", creditorName: "PHF Finance" },
  { id: "532383000087097672", creditorName: "FI Fortune" },
  { id: "532383000087096559", creditorName: "Jar" },
  { id: "532383000087029969", creditorName: "Fi" },
  { id: "532383000086633258", creditorName: "PROPELLD" },
  { id: "532383000084782170", creditorName: "RULOANS" },
  { id: "532383000083714219", creditorName: "Avanti Finance" },
  { id: "532383000083714196", creditorName: "Surjit finance" },
  { id: "532383000083095954", creditorName: "koodana" },
  { id: "532383000082450365", creditorName: "Mobikwik" },
  { id: "532383000080385647", creditorName: "IDFC Bank" },
  { id: "532383000077816029", creditorName: "Loaninhours" },
  { id: "532383000076495354", creditorName: "Credigen" },
  { id: "532383000076337947", creditorName: "Prayaan Capital Private Limited" },
  { id: "532383000071618185", creditorName: "SalaryCredits" },
  { id: "532383000070202102", creditorName: "Finikash" },
  { id: "532383000063115929", creditorName: "No Broker Insta Cash" },
  { id: "532383000058116820", creditorName: "Delta Finance" },
  { id: "532383000055233875", creditorName: "Airtel Finance" },
  { id: "532383000053369511", creditorName: "Protium loan" },
  { id: "532383000053126196", creditorName: "SIDBI" },
  { id: "532383000046439309", creditorName: "SMFG India Credit" },
  { id: "532383000046075157", creditorName: "Zet Loan" },
  { id: "532383000046075139", creditorName: "Kreditme" },
  { id: "532383000044741603", creditorName: "Cyber Cred" },
  { id: "532383000041421128", creditorName: "Rupeek" },
  { id: "532383000041308415", creditorName: "loan in need" },
  { id: "532383000040639429", creditorName: "Day Today Loan" },
  { id: "532383000040171842", creditorName: "Ram Fincorp" },
  { id: "532383000038873071", creditorName: "Phone Pe Loan" },
  { id: "532383000038873053", creditorName: "Loan Prime" },
  { id: "532383000037036205", creditorName: "Kredito24" },
  { id: "532383000034598904", creditorName: "Ram Fincorp" },
  { id: "532383000033900475", creditorName: "IND Money" },
  { id: "532383000031068920", creditorName: "Veritas Finance Private Limited" },
  { id: "532383000031023049", creditorName: "Trillion loans" },
  { id: "532383000025736581", creditorName: "Achiievers Finance" },
  { id: "532383000025736580", creditorName: "Ndx P2p Private Limited" },
  { id: "532383000025736579", creditorName: "Sarvagram Fincare Pvt Ltd." },
  {
    id: "532383000025736578",
    creditorName: "Devmuni Leasing and Finance Limited",
  },
  { id: "532383000025736577", creditorName: "Unifinz Capital India Limited" },
  {
    id: "532383000025736576",
    creditorName: "DOSLEASE AND FINANCE PRIVATE LIMITED",
  },
  {
    id: "532383000016739347",
    creditorName: "Manikaran Credit and Leasing Co. Pvt Ltd",
  },
  { id: "532383000016739346", creditorName: "Rupee112" },
  { id: "532383000016739345", creditorName: "Funds In Time" },
  { id: "532383000016739344", creditorName: "Advance Salary" },
  { id: "532383000016739343", creditorName: "Phone Pay" },
  { id: "532383000016739342", creditorName: "Credit In Time" },
  { id: "532383000016739341", creditorName: "Sabka Loan" },
  { id: "532383000016739340", creditorName: "Dpal" },
  { id: "532383000016739339", creditorName: "Shukla Finance" },
  { id: "532383000006046930", creditorName: "Thazhayil Finance" },
  { id: "532383000006046929", creditorName: "clickredit" },
  { id: "532383000006046928", creditorName: "Jhatpat Loans" },
  { id: "532383000006046927", creditorName: "InstaNova" },
  { id: "532383000006046926", creditorName: "Goyalass" },
  { id: "532383000006046925", creditorName: "Credit Plus" },
  {
    id: "532383000006046924",
    creditorName: "LUHARIWALA FINANCE & INVESTMENT PVT. LTD",
  },
  { id: "532383000006046923", creditorName: "Quick Money" },
  { id: "532383000006046922", creditorName: "Money On Click" },
  { id: "532383000006046921", creditorName: "Pizza speed loan" },
  { id: "532383000006046920", creditorName: "Gmoney" },
  { id: "532383000006046919", creditorName: "FatakPay Digital Pvt. Ltd." },
  { id: "532383000006046918", creditorName: "MCM Financiers" },
  {
    id: "532383000006046917",
    creditorName: "Western capital advisors Pvt Ltd",
  },
  { id: "532383000006046916", creditorName: "Whizdm Finance" },
  { id: "532383000006046915", creditorName: "A A Kredit" },
  { id: "532383000006046914", creditorName: "INDMoney" },
  { id: "532383000006046913", creditorName: "Speel Finance" },
  { id: "532383000006046912", creditorName: "Credit Fair" },
  { id: "532383000006046911", creditorName: "India Money Lending" },
  { id: "532383000006046910", creditorName: "Jankalyan Sahakari Bank Ltd" },
  { id: "532383000006046909", creditorName: "Grow Money Capital PVT LTD" },
  { id: "532383000006046908", creditorName: "Axio" },
  { id: "532383000006046907", creditorName: "zype" },
  { id: "532383000006046906", creditorName: "Next Fin" },
  { id: "532383000006046905", creditorName: "Instant Mudra" },
  { id: "532383000006046904", creditorName: "Tradofina" },
  { id: "532383000006046903", creditorName: "Money Wide" },
  { id: "532383000006046902", creditorName: "Bharat Loan" },
  { id: "532383000006046901", creditorName: "Privo" },
  {
    id: "532383000006046900",
    creditorName: "The Bhatkal Urban Co - Operative Bank Limited",
  },
  { id: "532383000006046899", creditorName: "Credifyn" },
  { id: "532383000006046898", creditorName: "Credify" },
  { id: "532383000006046897", creditorName: "IFMR Capital" },
  { id: "532383000006046896", creditorName: "Loanifi" },
  { id: "532383000006046895", creditorName: "Urban Money" },
  { id: "532383000006046894", creditorName: "Citizen Cooperative Bank" },
  { id: "532383000006046893", creditorName: "Friendloan" },
  { id: "532383000006046892", creditorName: "Ramfin Finance" },
  { id: "532383000006046891", creditorName: "Earnox Finance" },
  { id: "532383000006046890", creditorName: "FlexPay" },
  { id: "532383000006046889", creditorName: "Udaan Capital" },
  { id: "532383000006046888", creditorName: "Vivriti" },
  { id: "532383000006046887", creditorName: "Ugro Capital" },
  { id: "532383000006046886", creditorName: "Upkar Finance Ltd" },
  { id: "532383000006046885", creditorName: "Rapid Money" },
  { id: "532383000006046884", creditorName: "RapiMoney" },
  { id: "532383000006046883", creditorName: "Aspire" },
  { id: "532383000006046882", creditorName: "K M GLOBAL CREDIT PVT LTD" },
  { id: "532383000006046881", creditorName: "Nitstone Finserv" },
  { id: "532383000006046880", creditorName: "UCA FINVEST PRIVATE LIMITED" },
  {
    id: "532383000006046879",
    creditorName: "D.Pal Leasing & Finance Co. Ltd.",
  },
  {
    id: "532383000006046878",
    creditorName: "UCA FINVEST PRIVATE LIMITED - Loaninneed",
  },
  {
    id: "532383000006046877",
    creditorName: "Datta Finance And Trading Private Limited",
  },
  {
    id: "532383000006046876",
    creditorName: "Goodskill Securities And Services Limited - Loanpey",
  },
  {
    id: "532383000006046875",
    creditorName: "Ayaan Fintech Private Limited - Kredify",
  },
  { id: "532383000006046874", creditorName: "FundsMama" },
  { id: "532383000006046873", creditorName: "Urgo Capital" },
  { id: "532383000006046872", creditorName: "Moneygram" },
  { id: "532383000006046871", creditorName: "Rain Instant Pay" },
  { id: "532383000006046870", creditorName: "I Mobile Pay" },
  { id: "532383000006046869", creditorName: "Freo Pay" },
  { id: "532383000006046612", creditorName: "Mahavira Finlease Limited" },
  {
    id: "532383000006046611",
    creditorName: "Datta Finance & Trading Pvt. Ltd",
  },
  { id: "532383000006046610", creditorName: "Girdhar Finlease Pvt Ltd" },
  { id: "532383000006046609", creditorName: "ORRISH FINANCE PRIVATE LIMITED" },
  { id: "532383000006046608", creditorName: "Freecharge" },
  { id: "532383000006046607", creditorName: "SRS FIntech" },
  { id: "532383000006046606", creditorName: "Niro Finance" },
  { id: "532383000006046605", creditorName: "Wortgage" },
  { id: "532383000006046604", creditorName: "Aparampaar Finance Pvt Ltd" },
  { id: "532383000006046603", creditorName: "Flipkart Paylater" },
  { id: "532383000006046602", creditorName: "Ola Postpaid" },
  { id: "532383000006046601", creditorName: "Flying Cash" },
  { id: "532383000006046600", creditorName: "Annapurna Finance" },
  {
    id: "532383000006046599",
    creditorName: "Cholamandalam Investment and Finance Company",
  },
  { id: "532383000006046598", creditorName: "PayTail" },
  { id: "532383000006046597", creditorName: "ClickMyLoan" },
  { id: "532383000006046596", creditorName: "Chintamani Finlease Ltd." },
  { id: "532383000006046595", creditorName: "LoanPey" },
  { id: "532383000006046594", creditorName: "E-kosh" },
  { id: "532383000006046593", creditorName: "CREDENCE" },
  { id: "532383000006046592", creditorName: "UCA FINVEST PRIVATE LIMITED" },
  { id: "532383000006046591", creditorName: "StuCred" },
  { id: "532383000006046590", creditorName: "Tattva Fincorp Limited" },
  {
    id: "532383000006046589",
    creditorName: "Karassery Service Co-operative Bank Ltd.",
  },
  { id: "532383000006046588", creditorName: "Moga Co-operative Bank" },
  { id: "532383000006046587", creditorName: "FIBE" },
  { id: "532383000006046586", creditorName: "PayRupik" },
  { id: "532383000006046585", creditorName: "Fullerton" },
  { id: "532383000006046584", creditorName: "BN Dhan" },
  { id: "532383000006046583", creditorName: "Cashwalle" },
  { id: "532383000006046582", creditorName: "Amazon Pay Later" },
  { id: "532383000006046581", creditorName: "Lendplus India" },
  { id: "532383000006046580", creditorName: "Cashmypayment" },
  { id: "532383000006046579", creditorName: "KreditMarvel" },
  { id: "532383000006046578", creditorName: "Xpressloan" },
  { id: "532383000006046577", creditorName: "Urgent paise" },
  { id: "532383000006046576", creditorName: "Wonder Loan" },
  { id: "532383000006046575", creditorName: "Piramal Financial Services" },
  { id: "532383000006046574", creditorName: "Ring" },
  { id: "532383000006046573", creditorName: "Shivakari Finance" },
  { id: "532383000006046572", creditorName: "Mobicred" },
  { id: "532383000006046571", creditorName: "Salaryday" },
  { id: "532383000006046570", creditorName: "SAMPATI SECURITIES LTD" },
  { id: "532383000006046569", creditorName: "National Co-operative Bank" },
  {
    id: "532383000006046568",
    creditorName: "Tamilnad Mercantile Bank Limited",
  },
  { id: "532383000006046567", creditorName: "Groww" },
  { id: "532383000006046566", creditorName: "South Indian Bank" },
  { id: "532383000006046565", creditorName: "TRANSACTREE" },
  { id: "532383000006046564", creditorName: "DIGI Money" },
  { id: "532383000006046563", creditorName: "Flipkart paylater" },
  { id: "532383000006046562", creditorName: "fincfriends pvt ltd" },
  { id: "532383000006046561", creditorName: "fincfriends pvt ltd" },
  { id: "532383000006046560", creditorName: "MS fincap private LTD" },
  {
    id: "532383000006046559",
    creditorName: "Credenc Web Technologies Private Limited",
  },
  { id: "532383000006046558", creditorName: "Capital India" },
  { id: "532383000006046557", creditorName: "Jupiter" },
  {
    id: "532383000006046556",
    creditorName: "FAIRASSETS TECHNOLOGIES INDIA PRIVATE LIMITED",
  },
  { id: "532383000006046555", creditorName: "My Salary Dost Fintech Pvt. Ltd" },
  { id: "532383000006046554", creditorName: "EZ Finance" },
  { id: "532383000006046553", creditorName: "Propelled" },
  { id: "532383000006046552", creditorName: "Paisa In Time" },
  { id: "532383000006046551", creditorName: "cash po" },
  { id: "532383000006046550", creditorName: "SAVE Financial Pvt Ltd" },
  { id: "532383000006046549", creditorName: "One Cash" },
  { id: "532383000006046548", creditorName: "Cash Bus" },
  { id: "532383000006046547", creditorName: "Unnati Microfin Private Ltd" },
  { id: "532383000006046546", creditorName: "Tala" },
  { id: "532383000006046545", creditorName: "Easy Fincare" },
  { id: "532383000006046544", creditorName: "Findeed Solutions" },
  { id: "532383000006046543", creditorName: "Lending Plate" },
  { id: "532383000006046542", creditorName: "My Shubh Life" },
  { id: "532383000006046541", creditorName: "Uni Card" },
  { id: "532383000006046540", creditorName: "Werize" },
  { id: "532383000006046539", creditorName: "V Card" },
  { id: "532383000006046538", creditorName: "Balwark Capital Private Ltd" },
  { id: "532383000006046537", creditorName: "Curry Cash" },
  { id: "532383000006046536", creditorName: "12% Club" },
  { id: "532383000006046535", creditorName: "Postpe" },
  { id: "532383000006046534", creditorName: "Pupil Finance Private Limited" },
  { id: "532383000006046533", creditorName: "Dayandnight Loan" },
  { id: "532383000006046532", creditorName: "Zincash" },
  { id: "532383000006046531", creditorName: "Tyto Cash" },
  {
    id: "532383000006046530",
    creditorName: "Bharat Financial Inclusion Limited",
  },
  { id: "532383000006046529", creditorName: "Bueno Finance" },
  { id: "532383000006046528", creditorName: "Dhanlaxmi bank" },
  { id: "532383000006046527", creditorName: "Lenditt" },
  { id: "532383000006046526", creditorName: "ASHV Finance Limited" },
  {
    id: "532383000006046525",
    creditorName: "HIVELOOP CAPITAL PRIVATE LIMITED",
  },
  {
    id: "532383000006046524",
    creditorName: "Uttarbanga Kshetriya Gramin Bank",
  },
  { id: "532383000006046523", creditorName: "Financepeer" },
  { id: "532383000006046522", creditorName: "Kisetsu Saison Finance" },
  { id: "532383000006046521", creditorName: "Vaibhav Vyapaar Private Limited" },
  { id: "532383000006046520", creditorName: "Richbond Capital Pvt Ltd" },
  { id: "532383000006046519", creditorName: "DAHAR MOTOR FINANCE LTD" },
  { id: "532383000006046518", creditorName: "Loantap" },
  { id: "532383000006046517", creditorName: "Suvarnayug Sahakari Bank" },
  { id: "532383000006046516", creditorName: "Suvarnayug Sahakari Bank" },
  { id: "532383000006046515", creditorName: "CHINMAY FINLEASE LIMITED" },
  { id: "532383000006046514", creditorName: "TAPSTART CAPITAL PVT LTD" },
  { id: "532383000006046513", creditorName: "Zest Money" },
  { id: "532383000006046512", creditorName: "ELECTRONICA FINANCE LIMITED" },
  { id: "532383000006046511", creditorName: "CSB Bank" },
  { id: "532383000006046510", creditorName: "Credit Saison" },
  { id: "532383000006046509", creditorName: "Bhagini nivedita Shakari bank" },
  { id: "532383000006046508", creditorName: "SBM Bank" },
  {
    id: "532383000006046507",
    creditorName: "Snapmint Financial Services Private Limited",
  },
  { id: "532383000006046506", creditorName: "5 Paisa" },
  { id: "532383000006046505", creditorName: "Jharkhand Rajya Garmin Bank" },
  { id: "532383000006046504", creditorName: "Ambit Finvest Private Limited" },
  { id: "532383000006046503", creditorName: "mannapuram finance limited" },
  { id: "532383000006046502", creditorName: "Kalandri Capital" },
  { id: "532383000006046501", creditorName: "Creditt" },
  { id: "532383000006046500", creditorName: "Clix Capital" },
  {
    id: "532383000006046499",
    creditorName:
      "Potters Cottage Industrial Co-Operative Society Bank Limited",
  },
  {
    id: "532383000006046498",
    creditorName: "Nimisha Finance India Private Limited",
  },
  { id: "532383000006046497", creditorName: "icici" },
  { id: "532383000006046496", creditorName: "GUARDIAN BANK" },
  { id: "532383000006046495", creditorName: "Upmove Capital Pvt Ltd" },
  { id: "532383000006046494", creditorName: "Paytm Postpaid" },
  { id: "532383000006046493", creditorName: "hdfc" },
  { id: "532383000006046492", creditorName: "Amazon pay (Personal Loan)" },
  { id: "532383000006046491", creditorName: "VIVRITI CAPITAL PRIVATE LIMITED" },
  { id: "532383000006046490", creditorName: "Spheeti Fintech Pvt Ltd" },
  { id: "532383000006046489", creditorName: "TATA SBI Card" },
  { id: "532383000006046488", creditorName: "Riveria" },
  { id: "532383000006046487", creditorName: "Pocketly" },
  { id: "532383000006046486", creditorName: "loan front" },
  { id: "532383000006046485", creditorName: "Malayalam Benefit Fund Limited" },
  {
    id: "532383000006046484",
    creditorName: "Thrissur district co-operative bank",
  },
  {
    id: "532383000006046483",
    creditorName: "Irinjalakuda Town Cooperative Bank",
  },
  { id: "532383000006046482", creditorName: "APARAMPAAR FINANCE PVT LTD" },
  {
    id: "532383000006046481",
    creditorName: "Punjab & Maharashtra Co-operative Bank",
  },
  { id: "532383000006046480", creditorName: "DMK Jaoli Bank" },
  { id: "532383000006046479", creditorName: "SelFin India" },
  {
    id: "532383000006046478",
    creditorName: "The Khattri Co-operative (U) Bank Ltd",
  },
  { id: "532383000006046477", creditorName: "Cashtree Finance (P) Ltd" },
  { id: "532383000006046476", creditorName: "Dhani OneFreedom card" },
  { id: "532383000006046475", creditorName: "Varthana loan" },
  { id: "532383000006046474", creditorName: "okcredit" },
  { id: "532383000006046473", creditorName: "LenDenClub" },
  { id: "532383000006046472", creditorName: "MAS Financial Services" },
  { id: "532383000006046471", creditorName: "Janata sahakari bank" },
  {
    id: "532383000006046470",
    creditorName: "Pimpri chinchwad Co operative bank",
  },
  { id: "532383000006046469", creditorName: "Rajgurunagar sahakari bank" },
  { id: "532383000006046468", creditorName: "saraswat bank" },
  { id: "532383000006046467", creditorName: "saraswat bank" },
  { id: "532383000006046466", creditorName: "Andhra pradesh grameena vikas" },
  { id: "532383000006046465", creditorName: "Adarsh co operative bank" },
  { id: "532383000006046464", creditorName: "LiquiLoans" },
  { id: "532383000006046463", creditorName: "The Chembur Nagrik Sahkari Bank" },
  { id: "532383000006046462", creditorName: "National Bank" },
  {
    id: "532383000006046461",
    creditorName: "SRI KALIDASA SAHAKARA BANK NIYAMITHA",
  },
  { id: "532383000006046460", creditorName: "Dhanseva Cooperative Bank" },
  { id: "532383000006046459", creditorName: "Loaney" },
  { id: "532383000006046458", creditorName: "EDUVANZ" },
  { id: "532383000006046457", creditorName: "Florance Capital" },
  { id: "532383000006046456", creditorName: "Navi" },
  { id: "532383000006046455", creditorName: "Navi finance" },
  { id: "532383000006046454", creditorName: "Prefr" },
  { id: "532383000006046453", creditorName: "HDFC MILLENIA CREDIT CARD" },
  { id: "532383000006046452", creditorName: "bank bazaar" },
  { id: "532383000006046451", creditorName: "simpl" },
  { id: "532383000006046450", creditorName: "simple loan" },
  { id: "532383000006046449", creditorName: "Ecash" },
  { id: "532383000006046448", creditorName: "PINNACLE CAPITAL" },
  { id: "532383000006046447", creditorName: "Salary Now" },
  { id: "532383000006046446", creditorName: "Arnold Holdings Ltd" },
  { id: "532383000006046445", creditorName: "LenDenClub" },
  { id: "532383000006046444", creditorName: "unnati loans" },
  { id: "532383000006046443", creditorName: "FLITE PAY" },
  { id: "532383000006046442", creditorName: "karvy" },
  { id: "532383000006046441", creditorName: "karvy financial service limited" },
  { id: "532383000006046440", creditorName: "ADITSH FINTECH PRIVATE LIMITED" },
  { id: "532383000006046439", creditorName: "Maya Cash" },
  { id: "532383000006046438", creditorName: "Fair Money" },
  { id: "532383000006046437", creditorName: "Branch" },
  { id: "532383000006046436", creditorName: "KCREDIT BEE" },
  { id: "532383000006046435", creditorName: "KCREDIT BEE" },
  { id: "532383000006046434", creditorName: "Bharat Co-operative Bank" },
  { id: "532383000006046433", creditorName: "Bharat Co-operative Bank" },
  { id: "532383000006046432", creditorName: "Bharat Chs Bank" },
  { id: "532383000006046431", creditorName: "aditya birla" },
  { id: "532383000006046430", creditorName: "Avanse Financial Services LTD" },
  { id: "532383000006046429", creditorName: "Axis Finance Limited" },
  { id: "532383000006046428", creditorName: "Ecofin" },
  { id: "532383000006046427", creditorName: "fusion micro finance" },
  { id: "532383000006046426", creditorName: "fusion micro finance" },
  { id: "532383000006046425", creditorName: "Home credit" },
  { id: "532383000006046424", creditorName: "Vasai Janata Sahakari Bank Ltd." },
  { id: "532383000006046423", creditorName: "DATSON EXPORTS" },
  { id: "532383000006046422", creditorName: "Rufilo" },
  {
    id: "532383000006046421",
    creditorName: "Sree Subramanyeswara Co-operative Bank",
  },
  { id: "532383000006046420", creditorName: "Jansatta Sahakari Bank" },
  { id: "532383000006046419", creditorName: "Samastha micro finance" },
  {
    id: "532383000006046418",
    creditorName: "Growth Source Financial Technologies",
  },
  { id: "532383000006046417", creditorName: "LoanTap Financial Technologies" },
  { id: "532383000006046416", creditorName: "Capital Now" },
  { id: "532383000006046415", creditorName: "InnoFin Solutions" },
  { id: "532383000006046414", creditorName: "Jalan Chemicals Pvt Ltd" },
  { id: "532383000006046413", creditorName: "cashwish" },
  { id: "532383000006046412", creditorName: "Mitron Capital Pvt Ltd" },
  {
    id: "532383000006046411",
    creditorName: "SI CREVA CAPITAL SERVICES PRIVATE LIMITED",
  },
  { id: "532383000006046410", creditorName: "Oriental bank of commerce" },
  { id: "532383000006046409", creditorName: "Deutsche Bank" },
  { id: "532383000006046408", creditorName: "Ratan Indian Finance" },
  { id: "532383000006046407", creditorName: "Kapital Tech" },
  {
    id: "532383000006046406",
    creditorName: "Kudos Finance & Investments Pvt Ltd",
  },
  { id: "532383000006046405", creditorName: "Everyday Loan India" },
  { id: "532383000006046404", creditorName: "Dues Financial Capital" },
  { id: "532383000006046403", creditorName: "Dhanvarsha Finvest Limited" },
  {
    id: "532383000006046402",
    creditorName: "Visage Holdings Finacials Private Limited",
  },
  { id: "532383000006046401", creditorName: "U GRO" },
  { id: "532383000006046400", creditorName: "DOVE FINANCE PVT LTD" },
  { id: "532383000006046399", creditorName: "SimplyCash." },
  { id: "532383000006046398", creditorName: "Equitas Small Finance Bank" },
  { id: "532383000006046397", creditorName: "Karnataka Bank" },
  { id: "532383000006046396", creditorName: "Apna Sahakari Bank" },
  { id: "532383000006046395", creditorName: "Utkarsh Small Finance Bank" },
  { id: "532383000006046394", creditorName: "TRUE CREDITS PRIVATE LIMITED" },
  { id: "532383000006046393", creditorName: "Indian Bank" },
  { id: "532383000006046392", creditorName: "Indian Overseas Bank" },
  { id: "532383000006046391", creditorName: "CRED" },
  { id: "532383000006046390", creditorName: "AU SMALL FINANCE BANK LIMITED" },
  { id: "532383000006046389", creditorName: "JAMMU AND KASHMIR BANK LIMITED" },
  {
    id: "532383000006046388",
    creditorName: "THE THANE BHARAT SAHAKARI BANK LIMITED",
  },
  { id: "532383000006046387", creditorName: "Mahindra FINANCE" },
  { id: "532383000006046386", creditorName: "Abhyudaya Co-operative Bank Ltd" },
  {
    id: "532383000006046385",
    creditorName: "Navi Mumbai Co-operative Bank Limited",
  },
  { id: "532383000006046384", creditorName: "Niyogin" },
  { id: "532383000006046383", creditorName: "Finzy - Bridge FinTech" },
  { id: "532383000006046382", creditorName: "Jan Lakshmi Finance" },
  { id: "532383000006046381", creditorName: "Ft Cash" },
  { id: "532383000006046380", creditorName: "Navi" },
  { id: "532383000006046379", creditorName: "L&T Finance" },
  { id: "532383000006046378", creditorName: "HDFC Jumbo Loan" },
  { id: "532383000006046377", creditorName: "Aye Finance" },
  { id: "532383000006046376", creditorName: "Quadrillion finance pvt ltd" },
  { id: "532383000006046375", creditorName: "SBI Bank" },
  { id: "532383000006046374", creditorName: "kreditzy" },
  { id: "532383000006046373", creditorName: "Hybrid Flexi" },
  { id: "532383000006046372", creditorName: "PNB Bank" },
  {
    id: "532383000006046371",
    creditorName: "Satin Creditcare Network Limited",
  },
  { id: "532383000006046370", creditorName: "Orange5" },
  { id: "532383000006046369", creditorName: "SVC Co-operative Bank" },
  { id: "532383000006046368", creditorName: "Corporation Bank" },
  { id: "532383000006046367", creditorName: "Finnable Credit Private Limited" },
  {
    id: "532383000006046366",
    creditorName: "SVAKARMA FINANCE PRIVATE LIMITED",
  },
  {
    id: "532383000006046365",
    creditorName: "Kinara Capital - Visage Holdings & Finance Pvt. Ltd",
  },
  { id: "532383000006046364", creditorName: "MoNeed" },
  { id: "532383000006046363", creditorName: "Nitstone Finserv" },
  {
    id: "532383000006046106",
    creditorName: "The Delhi State Co-Operative Bank LTD",
  },
  { id: "532383000006046105", creditorName: "BharatPay" },
  { id: "532383000006046104", creditorName: "SBFC Finance Private Limited" },
  { id: "532383000006046103", creditorName: "DHFL" },
  { id: "532383000006046102", creditorName: "FlexiLoans" },
  { id: "532383000006046101", creditorName: "DCB Bank" },
  { id: "532383000006046100", creditorName: "Naman Finlease Pvt.Ltd" },
  { id: "532383000006046099", creditorName: "Mo Need" },
  { id: "532383000006046098", creditorName: "More Rupee" },
  { id: "532383000006046097", creditorName: "karur Vysya" },
  { id: "532383000006046096", creditorName: "i-Lend" },
  { id: "532383000006046095", creditorName: "ATD Finance" },
  { id: "532383000006046094", creditorName: "ATD Finance" },
  { id: "532383000006046093", creditorName: "ATD Finance" },
  { id: "532383000006046092", creditorName: "Citicorp Finance" },
  { id: "532383000006046091", creditorName: "VIVA Finance" },
  { id: "532383000006046090", creditorName: "Neev Finance" },
  { id: "532383000006046089", creditorName: "CashIn" },
  { id: "532383000006046088", creditorName: "Apollo Finvest India Limited" },
  { id: "532383000006046087", creditorName: "E Rupee" },
  { id: "532383000006046086", creditorName: "Rupee Plus" },
  { id: "532383000006046085", creditorName: "Cash Mama" },
  { id: "532383000006046084", creditorName: "Rupee Star" },
  { id: "532383000006046083", creditorName: "Billion Cash" },
  { id: "532383000006046082", creditorName: "Money Click" },
  { id: "532383000006046081", creditorName: "Get Rupee" },
  { id: "532383000006046080", creditorName: "True balance" },
  { id: "532383000006046079", creditorName: "True funds" },
  { id: "532383000006046078", creditorName: "Hero Fincorp" },
  { id: "532383000006046077", creditorName: "Hero Finance" },
  { id: "532383000006046076", creditorName: "Pay later" },
  { id: "532383000006046075", creditorName: "Shri Ram Finance" },
  { id: "532383000006046074", creditorName: "Money In Minute" },
  { id: "532383000006046073", creditorName: "Cash Suvidha" },
  { id: "532383000006046072", creditorName: "Muthoot Finance" },
  { id: "532383000006046071", creditorName: "okcash" },
  { id: "532383000006046070", creditorName: "Digi bank" },
  { id: "532383000006046069", creditorName: "BMI Finance" },
  { id: "532383000006046068", creditorName: "PayMeIndia" },
  { id: "532383000006046067", creditorName: "Mpokket" },
  { id: "532383000006046066", creditorName: "Allahabad Bank" },
  { id: "532383000006046065", creditorName: "Andhra Bank" },
  { id: "532383000006046064", creditorName: "Bhanix finance and investment" },
  { id: "532383000006046063", creditorName: "Cash TM" },
  { id: "532383000006046062", creditorName: "Flash rupee" },
  { id: "532383000006046061", creditorName: "Nanocred" },
  { id: "532383000006046060", creditorName: "Credi me" },
  { id: "532383000006046059", creditorName: "kredit 1" },
  { id: "532383000006046058", creditorName: "Cash Bowl" },
  { id: "532383000006046057", creditorName: "Sweet rupee" },
  { id: "532383000006046056", creditorName: "Rupee redee" },
  { id: "532383000006046055", creditorName: "AB cash" },
  { id: "532383000006046054", creditorName: "Easy cash" },
  { id: "532383000006046053", creditorName: "Double Cash" },
  { id: "532383000006046052", creditorName: "ICredit" },
  { id: "532383000006046051", creditorName: "Len Den Club" },
  { id: "532383000006046050", creditorName: "KreditBee" },
  { id: "532383000006046049", creditorName: "UCO BANK" },
  { id: "532383000006046048", creditorName: "I Lend" },
  { id: "532383000006046047", creditorName: "Eye lend" },
  { id: "532383000006046046", creditorName: "Vashu Finance" },
  { id: "532383000006046045", creditorName: "OM KOTAK Bank" },
  { id: "532383000006046044", creditorName: "tvs" },
  { id: "532383000006046043", creditorName: "Kashi Gomti Samyut Gramin Bank" },
  { id: "532383000006046042", creditorName: "INCRED INDIA" },
  { id: "532383000006046041", creditorName: "Paycheq" },
  { id: "532383000006046040", creditorName: "Kotak Mahindra Bank" },
  { id: "532383000006046039", creditorName: "Bajaj Finserv Ltd" },
  { id: "532383000006046038", creditorName: "TATA Card" },
  { id: "532383000006046037", creditorName: "SMC Finance" },
  { id: "532383000006046036", creditorName: "SmallBorrowing" },
  {
    id: "532383000006046035",
    creditorName: "RattanIndia Finance Private Limited",
  },
  { id: "532383000006046034", creditorName: "OxyLoans" },
  { id: "532383000006046033", creditorName: "Neo Growth Credit Pvt Ltd" },
  { id: "532383000006046032", creditorName: "MINTIFI PVT LTD" },
  { id: "532383000006046031", creditorName: "Magma Fincorp Limited" },
  { id: "532383000006046030", creditorName: "LoanTap Financial Technologies" },
  { id: "532383000006046029", creditorName: "Kangra Cooperative Bank Ltd" },
  { id: "532383000006046028", creditorName: "InstaMoney/LenDenClub" },
  { id: "532383000006046027", creditorName: "HSBC" },
  { id: "532383000006046026", creditorName: "HDB Financial Services" },
  { id: "532383000006046025", creditorName: "Credit Sudhar" },
  {
    id: "532383000006046024",
    creditorName: "HDFC Credila Financial Services Private Limited",
  },
  {
    id: "532383000006046023",
    creditorName: "Clix Finance India Private Limited",
  },
  { id: "532383000006046022", creditorName: "CashPro" },
  { id: "532383000006046021", creditorName: "CashBean" },
  { id: "532383000006046020", creditorName: "Bussan Auto Finance" },
  { id: "532383000006046019", creditorName: "Aditya Birla Finance Limited" },
  { id: "532383000006046018", creditorName: "RBL Bank Money Tap" },
  { id: "532383000006046017", creditorName: "ANY DAY LOAN" },
  { id: "532383000006046016", creditorName: "EDELWEISS" },
  { id: "532383000006046015", creditorName: "shri samartha corperative bank" },
  { id: "532383000006046014", creditorName: "go to cash" },
  { id: "532383000006046013", creditorName: "oml" },
  { id: "532383000006046012", creditorName: "LOANBABA" },
  { id: "532383000006046011", creditorName: "AnyTimeLoan" },
  { id: "532383000006046010", creditorName: "KNAB FINANCE" },
  { id: "532383000006046009", creditorName: "JAIN SONS FINLEASE" },
  { id: "532383000006046008", creditorName: "Ganesh Leasfin Private Limited" },
  { id: "532383000006046007", creditorName: "moneytap" },
  { id: "532383000006046006", creditorName: "Vig K Finance Pvt. Ltd." },
  { id: "532383000006046005", creditorName: "MI Credit" },
  { id: "532383000006046004", creditorName: "RUPEE MAX" },
  { id: "532383000006046003", creditorName: "Rupee De" },
  { id: "532383000006046002", creditorName: "prest loans" },
  { id: "532383000006046001", creditorName: "ocash" },
  { id: "532383000006046000", creditorName: "SlicePay" },
  { id: "532383000006045999", creditorName: "Pay U Finance" },
  { id: "532383000006045998", creditorName: "ZestMoney" },
  { id: "532383000006045997", creditorName: "IndiaLends" },
  { id: "532383000006045996", creditorName: "RedCarpet" },
  {
    id: "532383000006045995",
    creditorName: "Maitreya Capital And Business Services Pvt. Ltd",
  },
  { id: "532383000006045994", creditorName: "Gromor Finance" },
  { id: "532383000006045993", creditorName: "ICICI bank" },
  {
    id: "532383000006045992",
    creditorName: "Upwards Fintech Services Pvt. Ltd",
  },
  { id: "532383000006045991", creditorName: "FINTREE" },
  { id: "532383000006045990", creditorName: "EPI MONEY" },
  { id: "532383000006045989", creditorName: "IBL finance" },
  { id: "532383000006045988", creditorName: "M Quick Rupee" },
  { id: "532383000006045987", creditorName: "LIC CARDS" },
  { id: "532383000006045986", creditorName: "Ready Credit" },
  { id: "532383000006045985", creditorName: "Auxilo Finserve PVT LTD" },
  { id: "532383000006045984", creditorName: "MMT FINANCE" },
  { id: "532383000006045983", creditorName: "WIFI Cash" },
  { id: "532383000006045982", creditorName: "Star loan" },
  { id: "532383000006045981", creditorName: "Lendbox" },
  { id: "532383000006045980", creditorName: "Robo Cash" },
  { id: "532383000006045979", creditorName: "Money Loji" },
  { id: "532383000006045978", creditorName: "Tatkal Loan India Pvt. Ltd." },
  { id: "532383000006045977", creditorName: "AADRILTD" },
  { id: "532383000006045976", creditorName: "RIVIPL" },
  { id: "532383000006045975", creditorName: "Super Cash" },
  { id: "532383000006045974", creditorName: "Cash Now" },
  { id: "532383000006045973", creditorName: "Akara Capital" },
  { id: "532383000006045972", creditorName: "Loan Home" },
  { id: "532383000006045971", creditorName: "paytm" },
  { id: "532383000006045970", creditorName: "Loanwalle" },
  { id: "532383000006045969", creditorName: "Shubh Loans" },
  { id: "532383000006045968", creditorName: "UDIO" },
  { id: "532383000006045967", creditorName: "SME Corner" },
  { id: "532383000006045966", creditorName: "Lotus finance" },
  { id: "532383000006045965", creditorName: "paisalo" },
  { id: "532383000006045964", creditorName: "ZIP LOAN" },
  { id: "532383000006045963", creditorName: "NORTHERN ARC CAPITAL LIMITED" },
  { id: "532383000006045962", creditorName: "Loanfront" },
  {
    id: "532383000006045961",
    creditorName: "Lokmangal Multistate Co operative society",
  },
  { id: "532383000006045960", creditorName: "I2I Funding" },
  { id: "532383000006045959", creditorName: "Paisadukan" },
  { id: "532383000006045958", creditorName: "Nira Finance" },
  { id: "532383000006045957", creditorName: "Small Business FinCredit India" },
  { id: "532383000006045956", creditorName: "Avail Finance" },
  { id: "532383000006045955", creditorName: "Creditt" },
  { id: "532383000006045954", creditorName: "Phocket" },
  { id: "532383000006045953", creditorName: "Pooja Finelease Ltd." },
  { id: "532383000006045952", creditorName: "Visakhapatnam Co-operative Bank" },
  { id: "532383000006045951", creditorName: "Chintamani Finlease Ltd" },
  { id: "532383000006045950", creditorName: "INDIFI" },
  { id: "532383000006045949", creditorName: "Capita Finance" },
  { id: "532383000006045948", creditorName: "MANBA FINANCE" },
  { id: "532383000006045947", creditorName: "AB Capital" },
  { id: "532383000006045946", creditorName: "RapidRupee" },
  { id: "532383000006045945", creditorName: "IDFC First bank" },
  { id: "532383000006045944", creditorName: "Capital Float" },
  { id: "532383000006045943", creditorName: "Pay day India" },
  { id: "532383000006045942", creditorName: "Reliance Money" },
  { id: "532383000006045941", creditorName: "GORUPEE" },
  { id: "532383000006045940", creditorName: "Financepeer" },
  { id: "532383000006045939", creditorName: "OYE Loans" },
  { id: "532383000006045938", creditorName: "Monexo" },
  { id: "532383000006045937", creditorName: "Jana Bank" },
  {
    id: "532383000006045936",
    creditorName: "Sankalp Coperative Credit Society",
  },
  { id: "532383000006045935", creditorName: "STC Bank" },
  {
    id: "532383000006045934",
    creditorName: "Vivifi India Finance Private Limited",
  },
  { id: "532383000006045933", creditorName: "SmartCoin" },
  { id: "532383000006045932", creditorName: "Mahanagar Co-Oprative Bank Ltd" },
  { id: "532383000006045931", creditorName: "Monedo" },
  { id: "532383000006045930", creditorName: "Aphelion Finance Pvt. Ltd" },
  { id: "532383000006045929", creditorName: "Phillip Capital Pte Ltd" },
  { id: "532383000006045928", creditorName: "Krazybee" },
  { id: "532383000006045927", creditorName: "Poonawalla Credit" },
  { id: "532383000006045926", creditorName: "IVL Finance Ltd" },
  { id: "532383000006045925", creditorName: "Sunidhi Capital Pvt Ltd" },
  { id: "532383000006045924", creditorName: "IndusInd Bank" },
  { id: "532383000006045923", creditorName: "Bajaj Finance Ltd" },
  { id: "532383000006045922", creditorName: "Onecard" },
  { id: "532383000006045921", creditorName: "Flash cash" },
  { id: "532383000006045920", creditorName: "Cashplus" },
  { id: "532383000006045919", creditorName: "Flex Salary" },
  { id: "532383000006045918", creditorName: "Credy" },
  { id: "532383000006045917", creditorName: "Aeon" },
  { id: "532383000006045916", creditorName: "LendingKart" },
  { id: "532383000006045915", creditorName: "IIFL" },
  { id: "532383000006045914", creditorName: "Bajaj Finserv RBL Bank" },
  { id: "532383000006045913", creditorName: "CREDIT BAZAAR" },
  { id: "532383000006045912", creditorName: "QUICK CREDIT" },
  { id: "532383000006045911", creditorName: "AbhiPaisa" },
  { id: "532383000006045910", creditorName: "Cashkumar" },
  { id: "532383000006045909", creditorName: "Indiabulls Dhani" },
  { id: "532383000006045908", creditorName: "Rupee Lend" },
  { id: "532383000006045907", creditorName: "LazyPay" },
  { id: "532383000006045906", creditorName: "Kissht" },
  { id: "532383000006045905", creditorName: "Just Money" },
  { id: "532383000006045904", creditorName: "Incred" },
  { id: "532383000006045903", creditorName: "DMI finance" },
  { id: "532383000006045902", creditorName: "Payme India" },
  { id: "532383000006045901", creditorName: "Z2P" },
  { id: "532383000006045900", creditorName: "TATA Capital" },
  { id: "532383000006045899", creditorName: "Standard Chartered" },
  { id: "532383000006045898", creditorName: "Drp Finance" },
  { id: "532383000006045897", creditorName: "Vintage Finance" },
  { id: "532383000006045896", creditorName: "Stashfin" },
  { id: "532383000006045895", creditorName: "SBI Cards" },
  { id: "532383000006045894", creditorName: "RupeeRedee" },
  { id: "532383000006045893", creditorName: "Money View" },
  { id: "532383000006045892", creditorName: "Mobikwik" },
  { id: "532383000006045891", creditorName: "Home Credit" },
  { id: "532383000006045890", creditorName: "Faircent" },
  { id: "532383000006045889", creditorName: "American Express" },
  { id: "532383000006045888", creditorName: "Yes Bank" },
  { id: "532383000006045887", creditorName: "Walnut" },
  { id: "532383000006045886", creditorName: "Union Bank of India" },
  { id: "532383000006045885", creditorName: "Ujjivan Small Finance Bank" },
  { id: "532383000006045884", creditorName: "Syndicate Bank" },
  { id: "532383000006045883", creditorName: "Suryoday Small Finance Bank" },
  { id: "532383000006045882", creditorName: "State Bank of India" },
  { id: "532383000006045881", creditorName: "Shriram City Union Finance" },
  { id: "532383000006045880", creditorName: "Saraswat Co-operative Bank" },
  { id: "532383000006045879", creditorName: "RBL Bank" },
  { id: "532383000006045878", creditorName: "Punjab National Bank" },
  { id: "532383000006045877", creditorName: "Phoenix ARC Private Limited" },
  { id: "532383000006045876", creditorName: "Paysense" },
  { id: "532383000006045875", creditorName: "Kalyan Janata Sahakari Bank" },
  { id: "532383000006045874", creditorName: "INDITRADE MICROFINANCE LIMITED" },
  { id: "532383000006045873", creditorName: "IDBI Bank" },
  { id: "532383000006045872", creditorName: "HDFC Bank" },
  {
    id: "532383000006045871",
    creditorName: "Greater Bombay Co-operative Bank",
  },
  {
    id: "532383000006045870",
    creditorName: "Fullerton India Credit Company Limited",
  },
  { id: "532383000006045869", creditorName: "Federal Bank" },
  { id: "532383000006045868", creditorName: "EarlySalary" },
  { id: "532383000006045867", creditorName: "DBS Bank" },
  { id: "532383000006045866", creditorName: "Citibank" },
  { id: "532383000006045865", creditorName: "Central Bank of India" },
  { id: "532383000006045864", creditorName: "CASHe" },
  { id: "532383000006045863", creditorName: "Canara Bank" },
  { id: "532383000006045862", creditorName: "Bank of Maharashtra" },
  { id: "532383000006045861", creditorName: "Bank of India" },
  { id: "532383000006045860", creditorName: "Bank of Baroda" },
  { id: "532383000006045859", creditorName: "Bandhan Bank" },
  { id: "532383000006045858", creditorName: "Axis Bank" },
  { id: "532383000006045857", creditorName: "AB Bank" },
];
