import axios from "axios";
import React, { createContext, useState } from "react";
const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const url = "https://api.singledebt.in";
  const [user, setUser] = useState(null);
  //
  const getToken = async () => {
    const res1 = await axios.get(`${url}/token`);
    if (res1.status === 400) {
      console.log("Something went wrong");
    } else if (res1.status === 200) {
      if (res1.data.token.length === 0) {
        return null;
      } else {
        return res1.data.token[0].token;
      }
    }
  };
  //
  const getAccountStatus = async (number) => {
    try {
      let token = await getToken();
      const res = await axios.get(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/search?criteria=((Phone_Number:equals:${number}))`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (!res.data || res.status === 204) {
        return {
          Account_Status: "Account not found",
          id: "",
          Step: "",
          Outstanding: "",
          No_Of_Loans: "",
          EMI_Payments: "",
          Missed_Payment: "",
          Harassment_Type: "",
          Legal_Status: "",
          Income: "",
          Expenses: "",
          Plan_Type: "",
          Monthly_EMI_Payment: "",
          Total_Plan_Amount: "",
          Enroll_Payment_Status: "",
          Subscription_Fees: "",
        };
      } else {
        return {
          Account_Status: res.data.data[0].Account_Status,
          id: res.data.data[0].id,
          Step: res.data.data[0].Step === null ? "" : res.data.data[0].Step,
          First_Name:
            res.data.data[0].First_Name === null
              ? ""
              : res.data.data[0].First_Name,
          Last_Name:
            res.data.data[0].Last_Name === null
              ? ""
              : res.data.data[0].Last_Name,
          Email: res.data.data[0].Email === null ? "" : res.data.data[0].Email,
          Phone_Number:
            res.data.data[0].Phone_Number === null
              ? ""
              : res.data.data[0].Phone_Number,
          Outstanding:
            res.data.data[0].Outstanding === null
              ? ""
              : res.data.data[0].Outstanding,
          No_Of_Loans:
            res.data.data[0].No_Of_Loans === null
              ? ""
              : res.data.data[0].No_Of_Loans,
          EMI_Payments:
            res.data.data[0].EMI_Payments === null
              ? ""
              : res.data.data[0].EMI_Payments,
          Missed_Payment:
            res.data.data[0].Missed_Payment === null
              ? ""
              : res.data.data[0].Missed_Payment,
          Harassment_Type:
            res.data.data[0].Harassment_Type === null
              ? ""
              : res.data.data[0].Harassment_Type,
          Legal_Status:
            res.data.data[0].Legal_Status === null
              ? ""
              : res.data.data[0].Legal_Status,
          Income:
            res.data.data[0].Income === null ? "" : res.data.data[0].Income,
          Expenses:
            res.data.data[0].Expenses === null ? "" : res.data.data[0].Expenses,
          Plan_Type:
            res.data.data[0].Plan_Type === null
              ? ""
              : res.data.data[0].Plan_Type,
          Monthly_EMI_Payment:
            res.data.data[0].Monthly_EMI_Payment === null
              ? ""
              : res.data.data[0].Monthly_EMI_Payment,
          Total_Plan_Amount:
            res.data.data[0].Total_Plan_Amount === null
              ? ""
              : res.data.data[0].Total_Plan_Amount,
          Enroll_Payment_Status:
            res.data.data[0].Enroll_Payment_Status === null
              ? ""
              : res.data.data[0].Enroll_Payment_Status,
          Subscription_Fees:
            res.data.data[0].Subscription_Fees === null
              ? ""
              : res.data.data[0].Subscription_Fees,
        };
      }
    } catch (error) {
      console.log(error);
      return { status: "Error", id: null };
    }
  };
  //
  const preventChange = (e) => {
    if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      e.preventDefault();
    }
  };
  const preventScroll = (e) => {
    if (document.activeElement === e.target) {
      e.preventDefault();
    }
  };
  //
  const localData = {
    Outstanding: "",
    No_Of_Loans: "",
    EMI_Payments: "",
    Missed_Payment: "",
    Harassment_Type: "",
    Legal_Status: "",
    Income: "",
    Expenses: "",
    Plan_Type: "",
    Monthly_EMI_Payment: "",
    Total_Plan_Amount: "",
    Enroll_Payment_Status: "",
  };
  return (
    <AppContext.Provider
      value={{
        url,
        user,
        setUser,
        getToken,
        getAccountStatus,
        preventChange,
        preventScroll,
        localData,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
