import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../context/AppContext";
import { NavLink, useNavigate } from "react-router-dom";
import {
  FaEdit,
  FaFile,
  FaPhoneAlt,
  FaUserAlt,
  FaUserSecret,
} from "react-icons/fa";
import { VscLaw } from "react-icons/vsc";
import { MdPayment } from "react-icons/md";
import { MdOutlineRestartAlt } from "react-icons/md";
import axios from "axios";
import { FaTrash } from "react-icons/fa";
import { creditors } from "../assets/data/creditors";

const Home = () => {
  const { url, user, getToken, getAccountStatus } = useContext(AppContext);
  //
  const getCreditorsList = async () => {
    try {
      const token = await getToken();
      const res = await axios.get(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${user.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      console.log();
      if (res.data.data === 0 || !res.data.data) {
        setCreditorsModal(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getCreditorsList();
  }, []);
  //
  const navigate = useNavigate();
  const inputTemplate = {
    Creditors_Name: "",
    Bank_Type: "",
    Type_of_Credit: "",
    Account_number: "",
    Balance_O_S: "",
    Current_monthly_EMI: "",
    No_of_Missed_EMI: "",
    Sanctioned_Amount: "",
  };
  const [inputs, setInputs] = useState([inputTemplate]);
  const addMore = () => {
    setInputs([...inputs, inputTemplate]);
  };
  const deleteInput = (index) => {
    const filtered = inputs.filter((item, id) => {
      return index !== id;
    });
    setInputs(filtered);
  };
  const handleInputs = (e, index) => {
    const updatedInputs = inputs.map((item, id) =>
      index === id
        ? Object.assign({ ...item, [e.target.name]: e.target.value })
        : item
    );
    setInputs(updatedInputs);
  };
  //
  const [creditorsModal, setCreditorsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  //
  const postCreditors = async () => {
    const hasEmptyFields = inputs.some((input) =>
      Object.values(input).some((value) => value === "")
    );
    if (hasEmptyFields) {
      setMessage("Please fill out all fields before submitting.");
      return;
    } else {
      setMessage("");
      setLoading(true);
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      const result = await getAccountStatus(sdUser);
      const token = await getToken();
      const data = [
        {
          id: result.id,
          Creditors_Debt: inputs,
        },
      ];
      try {
        const res = await axios.put(
          `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          }
        );
        console.log(res.status);
        //
        if (res.status === 200) {
          navigate("/", { replace: true });
        } else {
          setMessage("Something went wrong");
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 10000);
    }
  }, [message]);
  return (
    <>
      {user !== null ? (
        <>
          <div className="container p-2 pt-0">
            <ul className="links my-4">
              <li>
                <NavLink to="/userdetails" className="white-button">
                  <FaUserAlt />
                  Your details
                </NavLink>
              </li>
              <li>
                <NavLink to="/reportchange" className="white-button">
                  <FaEdit />
                  Report a change of circumstance
                </NavLink>
              </li>
              <li>
                <NavLink to="/advocatelawyer" className="white-button">
                  <VscLaw />
                  Advocate & Lawyers
                </NavLink>
              </li>
              <li>
                <NavLink to="/paralegal" className="white-button">
                  <FaPhoneAlt />
                  Paralegal - calls & harassments
                </NavLink>
              </li>
              <li>
                <NavLink to="/makepayment" className="white-button">
                  <MdPayment />
                  Make a payment
                </NavLink>
              </li>
              <li>
                <NavLink to="/accountmanager" className="white-button">
                  <FaUserSecret />
                  Contact details of your account manager, T&C
                </NavLink>
              </li>
              <li>
                <NavLink to="/documents" className="white-button">
                  <FaFile />
                  Documents
                </NavLink>
              </li>
              <li>
                <a
                  href="https://enach.singledebt.in"
                  target="_blank"
                  rel="noreferrer"
                  className="white-button"
                >
                  <MdOutlineRestartAlt />
                  Setup eNACH
                </a>
              </li>
            </ul>
            <div className="text-end logout"></div>
          </div>

          {/*  */}
          <div
            className={`custom-modal-container ${
              creditorsModal ? "active" : ""
            }`}
          >
            <div
              className="custom-modal-background"
              onClick={() => setCreditorsModal(false)}
            ></div>
            <div
              className="custom-modal-box container"
              style={{ width: "100%" }}
            >
              <div className={`body p-2 text-center rounded `}>
                <h1 className="my-2">Add creditors</h1>
                {inputs.map((item, index) => {
                  return (
                    <div className="creditors-grid mb-4" key={index}>
                      <div className="creditors-grid-head">
                        <h1>Creditor {index + 1}</h1>
                        {inputs.length > 1 ? (
                          <button
                            className="button"
                            onClick={() => deleteInput(index)}
                          >
                            <FaTrash />
                          </button>
                        ) : (
                          ""
                        )}
                      </div>
                      <div>
                        <div className="form-control">
                          <select
                            name="Creditors_Name"
                            className="form-input"
                            required
                            autoComplete="off"
                            value={item.Creditors_Name}
                            onChange={(e) => handleInputs(e, index)}
                          >
                            <option value=""></option>
                            {creditors
                              .sort((a, b) =>
                                a.creditorName.localeCompare(b.creditorName)
                              )
                              .map((item, index) => {
                                return (
                                  <option value={item.id} key={index}>
                                    {item.creditorName}
                                  </option>
                                );
                              })}
                          </select>
                          <label
                            htmlFor="Creditors_Name"
                            className="form-label input-label"
                          >
                            Creditor's name
                          </label>
                        </div>
                        <p className="text-danger"></p>
                      </div>
                      <div>
                        <div className="form-control">
                          <select
                            name="Bank_Type"
                            className="form-input"
                            required
                            autoComplete="off"
                            value={item.Bank_Type}
                            onChange={(e) => handleInputs(e, index)}
                          >
                            <option value=""></option>
                            <option value="bank loan">Bank loan</option>
                            <option value="app loan">App loan</option>
                          </select>
                          <label
                            htmlFor="Bank_Type"
                            className="form-label input-label"
                          >
                            Bank type
                          </label>
                        </div>
                        <p className="text-danger"></p>
                      </div>
                      <div>
                        <div className="form-control">
                          <select
                            name="Type_of_Credit"
                            className="form-input"
                            required
                            autoComplete="off"
                            value={item.Type_of_Credit}
                            onChange={(e) => handleInputs(e, index)}
                          >
                            <option value=""></option>
                            <option value="Credit Card">Credit Card</option>
                            <option value="Personal Loan">Personal Loan</option>
                            <option value="Overdraft">Overdraft</option>
                            <option value="Business loan">Business loan</option>
                            <option value="Education loan">
                              Education loan
                            </option>
                            <option value="Store card">Store card</option>
                            <option value="Pay day loan">Pay day loan</option>
                            <option value="Consumer Durable">
                              Consumer Durable
                            </option>
                            <option value="Digital Product">
                              Digital Product
                            </option>
                            <option value="Credit Line">Credit Line</option>
                            <option value="Bullet Loan">Bullet Loan</option>
                            <option value="Term Loan">Term Loan</option>
                            <option value="Business Loan Unsecured">
                              Business Loan Unsecured
                            </option>
                            <option value="Business Loan Secured">
                              Business Loan Secured
                            </option>
                            <option value="LAP">LAP</option>
                            <option value="Secured Overdraft">
                              Secured Overdraft
                            </option>
                            <option value="Unsecured Overdraft">
                              Unsecured Overdraft
                            </option>
                            <option value="Emergency Credit Line Guarantee Scheme">
                              Emergency Credit Line Guarantee Scheme
                            </option>
                          </select>
                          <label
                            htmlFor="Type_of_Credit"
                            className="form-label input-label"
                          >
                            Type of credit
                          </label>
                        </div>
                        <p className="text-danger"></p>
                      </div>
                      <div>
                        <div className="form-control">
                          <input
                            type="number"
                            name="Account_number"
                            className="form-input"
                            required
                            autoComplete="off"
                            value={item.Account_number}
                            onChange={(e) => handleInputs(e, index)}
                          />
                          <label
                            htmlFor="Account_number"
                            className="form-label input-label"
                          >
                            Account number
                          </label>
                        </div>
                        <p className="text-danger"></p>
                      </div>
                      <div>
                        <div className="form-control">
                          <input
                            type="number"
                            name="Balance_O_S"
                            className="form-input"
                            required
                            autoComplete="off"
                            value={item.Balance_O_S}
                            onChange={(e) => handleInputs(e, index)}
                          />
                          <label
                            htmlFor="Balance_O_S"
                            className="form-label input-label"
                          >
                            Balanse outstanding
                          </label>
                        </div>
                        <p className="text-danger"></p>
                      </div>
                      <div>
                        <div className="form-control">
                          <input
                            type="number"
                            name="Current_monthly_EMI"
                            className="form-input"
                            required
                            autoComplete="off"
                            value={item.Current_monthly_EMI}
                            onChange={(e) => handleInputs(e, index)}
                          />
                          <label
                            htmlFor="Current_monthly_EMI"
                            className="form-label input-label"
                          >
                            Current monthly EMI
                          </label>
                        </div>
                        <p className="text-danger"></p>
                      </div>
                      <div>
                        <div className="form-control">
                          <input
                            type="number"
                            name="No_of_Missed_EMI"
                            className="form-input"
                            required
                            autoComplete="off"
                            value={item.No_of_Missed_EMI}
                            onChange={(e) => handleInputs(e, index)}
                          />
                          <label
                            htmlFor="No_of_Missed_EMI"
                            className="form-label input-label"
                          >
                            Number of missed EMI
                          </label>
                        </div>
                        <p className="text-danger"></p>
                      </div>
                      <div>
                        <div className="form-control">
                          <input
                            type="number"
                            name="Sanctioned_Amount"
                            className="form-input"
                            required
                            autoComplete="off"
                            value={item.Sanctioned_Amount}
                            onChange={(e) => handleInputs(e, index)}
                          />
                          <label
                            htmlFor="Sanctioned_Amount"
                            className="form-label input-label"
                          >
                            Sanctioned Amount
                          </label>
                        </div>
                        <p className="text-danger"></p>
                      </div>
                    </div>
                  );
                })}
                <hr />
                <div className="text-end mb-2">
                  <p className="text-danger">{message}</p>
                </div>
                <div className="d-flex align-items-center justify-content-end gap-2">
                  <button
                    className="button text-center"
                    onClick={addMore}
                    style={{ minWidth: "90px" }}
                  >
                    Add more
                  </button>
                  <button
                    className="button text-center"
                    style={{ minWidth: "90px" }}
                    onClick={postCreditors}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <>Submit</>
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default Home;
