import React, { useContext, useEffect, useState } from "react";
import Assistance from "../components/Assistance";
import { FaTrash } from "react-icons/fa";
import { creditors } from "../assets/data/creditors";
import axios from "axios";
import { AppContext } from "../context/AppContext";
import { useNavigate } from "react-router-dom";

const Creditors = () => {
  const { url, getAccountStatus, getToken } = useContext(AppContext);
  const navigate = useNavigate();
  const inputTemplate = {
    Creditors_Name: "",
    Bank_Type: "",
    Type_of_Credit: "",
    Account_number: "",
    Balance_O_S: "",
    Current_monthly_EMI: "",
    No_of_Missed_EMI: "",
    Sanctioned_Amount: "",
  };
  const [inputs, setInputs] = useState([inputTemplate]);
  //
  const addMore = () => {
    setInputs([...inputs, inputTemplate]);
  };
  //
  const deleteInput = (index) => {
    const filtered = inputs.filter((item, id) => {
      return index !== id;
    });
    setInputs(filtered);
  };
  //
  const handleInputs = (e, index) => {
    const updatedInputs = inputs.map((item, id) =>
      index === id
        ? Object.assign({ ...item, [e.target.name]: e.target.value })
        : item
    );
    setInputs(updatedInputs);
  };
  //
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  //
  const openModal = () => {
    const hasEmptyFields = inputs.some((input) =>
      Object.values(input).some((value) => value === "")
    );
    if (hasEmptyFields) {
      setMessage("Please fill out all fields before submitting.");
      return;
    } else {
      setModal(true);
    }
  };
  //
  const postCreditors = async () => {
    setMessage("");
    setLoading(true);
    const sdUser = JSON.parse(localStorage.getItem("sdUser"));
    const result = await getAccountStatus(sdUser);
    const token = await getToken();
    const data = [
      {
        id: result.id,
        Creditors_Debt: inputs,
      },
    ];
    try {
      const res = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      console.log(res.status);
      //
      if (res.status === 200) {
        navigate("/", { replace: true });
      } else {
        setMessage("Something went wrong");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  //
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 10000);
    }
  }, [message]);
  //
  const [modal, setModal] = useState(false);
  return (
    <>
      <div className="container p-2">
        <Assistance body="Please provide your creditors details" />
        <div className="assistance-seperator"></div>
        <h2 className="mb-4 text-center">
          Enter Unsecured debts Details (Credit Cards & Personal Loans ect..)
        </h2>
        {inputs.map((item, index) => {
          return (
            <div className="creditors-grid mb-4" key={index}>
              <div className="creditors-grid-head mb-2">
                <h1>Creditor {index + 1}</h1>
                {inputs.length > 1 ? (
                  <button className="button" onClick={() => deleteInput(index)}>
                    <FaTrash />
                  </button>
                ) : (
                  ""
                )}
              </div>
              <div>
                <div className="form-control mb-3">
                  <select
                    name="Creditors_Name"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={item.Creditors_Name}
                    onChange={(e) => handleInputs(e, index)}
                  >
                    <option value=""></option>
                    {creditors
                      .sort((a, b) =>
                        a.creditorName.localeCompare(b.creditorName)
                      )
                      .map((item, index) => {
                        return (
                          <option value={item.id} key={index}>
                            {item.creditorName}
                          </option>
                        );
                      })}
                  </select>
                  <label
                    htmlFor="Creditors_Name"
                    className="form-label input-label"
                  >
                    Creditor's name
                  </label>
                </div>
                <p className="text-danger"></p>
              </div>
              <div>
                <div className="form-control mb-3">
                  <select
                    name="Bank_Type"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={item.Bank_Type}
                    onChange={(e) => handleInputs(e, index)}
                  >
                    <option value=""></option>
                    <option value="bank loan">Bank loan</option>
                    <option value="app loan">App loan</option>
                  </select>
                  <label htmlFor="Bank_Type" className="form-label input-label">
                    Bank type
                  </label>
                </div>
                <p className="text-danger"></p>
              </div>
              <div>
                <div className="form-control mb-3">
                  <select
                    name="Type_of_Credit"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={item.Type_of_Credit}
                    onChange={(e) => handleInputs(e, index)}
                  >
                    <option value=""></option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Personal Loan">Personal Loan</option>
                    <option value="Overdraft">Overdraft</option>
                    <option value="Business loan">Business loan</option>
                    <option value="Education loan">Education loan</option>
                    <option value="Store card">Store card</option>
                    <option value="Pay day loan">Pay day loan</option>
                    <option value="Consumer Durable">Consumer Durable</option>
                    <option value="Digital Product">Digital Product</option>
                    <option value="Credit Line">Credit Line</option>
                    <option value="Bullet Loan">Bullet Loan</option>
                    <option value="Term Loan">Term Loan</option>
                    <option value="Business Loan Unsecured">
                      Business Loan Unsecured
                    </option>
                    <option value="Business Loan Secured">
                      Business Loan Secured
                    </option>
                    <option value="LAP">LAP</option>
                    <option value="Secured Overdraft">Secured Overdraft</option>
                    <option value="Unsecured Overdraft">
                      Unsecured Overdraft
                    </option>
                    <option value="Emergency Credit Line Guarantee Scheme">
                      Emergency Credit Line Guarantee Scheme
                    </option>
                  </select>
                  <label
                    htmlFor="Type_of_Credit"
                    className="form-label input-label"
                  >
                    Type of credit
                  </label>
                </div>
                <p className="text-danger"></p>
              </div>
              <div>
                <div className="form-control mb-3">
                  <input
                    type="number"
                    name="Account_number"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={item.Account_number}
                    onChange={(e) => handleInputs(e, index)}
                  />
                  <label
                    htmlFor="Account_number"
                    className="form-label input-label"
                  >
                    Account number
                  </label>
                </div>
                <p className="text-danger"></p>
              </div>
              <div>
                <div className="form-control mb-3">
                  <input
                    type="number"
                    name="Balance_O_S"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={item.Balance_O_S}
                    onChange={(e) => handleInputs(e, index)}
                  />
                  <label
                    htmlFor="Balance_O_S"
                    className="form-label input-label"
                  >
                    Balanse outstanding
                  </label>
                </div>
                <p className="text-danger"></p>
              </div>
              <div>
                <div className="form-control mb-3">
                  <input
                    type="number"
                    name="Current_monthly_EMI"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={item.Current_monthly_EMI}
                    onChange={(e) => handleInputs(e, index)}
                  />
                  <label
                    htmlFor="Current_monthly_EMI"
                    className="form-label input-label"
                  >
                    Current monthly EMI
                  </label>
                </div>
                <p className="text-danger"></p>
              </div>
              <div>
                <div className="form-control mb-3">
                  <input
                    type="number"
                    name="No_of_Missed_EMI"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={item.No_of_Missed_EMI}
                    onChange={(e) => handleInputs(e, index)}
                  />
                  <label
                    htmlFor="No_of_Missed_EMI"
                    className="form-label input-label"
                  >
                    Number of missed EMI
                  </label>
                </div>
                <p className="text-danger"></p>
              </div>
              <div>
                <div className="form-control mb-3">
                  <input
                    type="number"
                    name="Sanctioned_Amount"
                    className="form-input"
                    required
                    autoComplete="off"
                    value={item.Sanctioned_Amount}
                    onChange={(e) => handleInputs(e, index)}
                  />
                  <label
                    htmlFor="Sanctioned_Amount"
                    className="form-label input-label"
                  >
                    Sanctioned Amount
                  </label>
                </div>
                <p className="text-danger"></p>
              </div>
            </div>
          );
        })}

        <hr />
        <div className="text-end mb-2">
          <p className="text-danger">{message}</p>
        </div>
        <div className="d-flex align-items-center justify-content-end gap-2">
          <button
            className="button text-center"
            onClick={addMore}
            style={{ minWidth: "90px" }}
          >
            Add more
          </button>
          <button
            className="button text-center"
            onClick={openModal}
            style={{ minWidth: "90px" }}
          >
            {loading ? (
              <div className="spinner-border spinner-border-sm" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            ) : (
              <>Submit</>
            )}
          </button>
        </div>
      </div>

      <div className={`custom-modal-container ${modal ? "active" : ""}`}>
        <div
          className="custom-modal-background"
          onClick={() => setModal(false)}
        ></div>
        <div className="custom-modal-box">
          <div className="body py-4 p-2 text-center rounded">
            <h2>
              You have added {inputs.length} creditors. Do you want to add more
              or submit?
            </h2>
            <div className="d-flex align-items-center justify-content-center gap-2 mt-3">
              <button className="white-button" onClick={() => setModal(false)}>
                Add more
              </button>
              <button className="button" onClick={postCreditors}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Creditors;
