import React, { useContext, useEffect, useState } from "react";
import lady from "../assets/images/Lady.png";
import whiteLogo from "../assets/images/white logo.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import { FaArrowRight } from "react-icons/fa";
import emi from "../assets/images/signin-icons/emi.png";
import harassment from "../assets/images/signin-icons/harassment.png";
import legal from "../assets/images/signin-icons/legal.png";
import creditscore from "../assets/images/signin-icons/creditscore.png";
import Apple from "../assets/images/signin-icons/Apple.png";
import Google from "../assets/images/signin-icons/Google.png";

const Signup2 = () => {
  const { url, getToken } = useContext(AppContext);
  const navigate = useNavigate();
  //
  const [inputs, setInputs] = useState({
    name: "",
    email: "",
    mobile: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  //
  const [searchParams] = useSearchParams();
  useEffect(() => {
    const referralName = searchParams.get("referral_name");
    const referralNumber = searchParams.get("referral_number");
    console.log(referralName, referralNumber);
    //
    if (referralNumber !== null || referralNumber !== "") {
      setInputs({
        ...inputs,
        mobile: referralNumber,
      });
    }
  }, []);
  //
  const signup = async () => {
    const utmSource = searchParams.get("utm_source");
    const { name, email, mobile } = inputs;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let emailTest = emailRegex.test(email);
    console.log(inputs);
    //
    if (!name) {
      setError(true);
      setMessage("Please enter your full name");
    } else if (!email) {
      setError(true);
      setMessage("Please enter your email address");
    } else if (!emailTest) {
      setError(true);
      setMessage("Please enter a valid email address");
    } else if (!mobile) {
      setError(true);
      setMessage("Please enter your mobile number");
    } else if (name.split(" ").length < 2) {
      setError(true);
      setMessage("Please enter your full name");
    } else {
      try {
        setLoading(true);
        const token = await getToken();
        //
        const firstName = name.split(" ")[0];
        const lastName = name.replace(name.split(" ")[0], "");
        //
        const data = {
          First_Name: firstName,
          Last_Name: lastName,
          Email: email,
          Phone_Number: mobile,
          Account_Status: "Enrolled",
          Sources:
            !utmSource || utmSource === "" ? "Enroll_Portal_Client" : utmSource,
          Service_Offered: "Online Personal Debt Program",
        };
        //
        const createUser = await axios.post(
          `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads`,
          [data],
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          }
        );
        if (createUser.status === 201) {
          setError(false);
          setMessage("Account created successfully.");
          localStorage.setItem("sdUser", JSON.stringify(inputs.mobile));
          setTimeout(() => {
            setLoading(false);
            navigate("/enroll", { replace: true });
          }, 3000);
        } else if (createUser.status === 202) {
          const field = createUser.data.data[0].details.api_name;
          //
          if (field === "Email") {
            setError(true);
            setMessage(`Duplicate data: Email address.`);
          } else if (field === "Phone_Number") {
            setError(true);
            setMessage(`Account already exists. Please login.`);
            setTimeout(() => {
              navigate("/login", { replace: true });
              setError(false);
            }, 3000);
          }
        } else if (!createUser.data || createUser.status === 204) {
          setError(true);
          setMessage(`Something went wrong.`);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  useEffect(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);
  return (
    <>
      <div className="signup2-container">
        <div className="lady-div">
          <img src={lady} alt="" />
        </div>
        <div className="content-div">
          <img src={whiteLogo} alt="" />
          <div className="my-4">
            <h2>Start Your Debt-Free Journey Today!</h2>
            <h1>Just Rs 599</h1>

            <div className="my-3">
              <p>
                Join <span className="fw-bold">SingleDebt</span> & take the
                first step toward <br />
                <span className="fw-bold">Financial Freedom</span>.
              </p>
            </div>

            <ul className="list-unstyled my-5">
              <li className="signin-icon-flex">
                {" "}
                <img src={emi} alt="" /> Affordable EMI Repayment Plan
              </li>
              <li className="signin-icon-flex">
                {" "}
                <img src={harassment} alt="" /> Harassment Relief
              </li>
              <li className="signin-icon-flex">
                {" "}
                <img src={legal} alt="" /> Legal Support
              </li>
              <li className="signin-icon-flex">
                {" "}
                <img src={creditscore} alt="" /> Credit Score Improvement
              </li>
            </ul>

            <div className="mb-3">
              <p>Be stress-free - Clear path to becoming debt-free.</p>
            </div>

            <div className="signup-text mt-5">
              <h1>Signup now</h1>
            </div>
          </div>
        </div>
        <div className="form-div">
          <div className="form-box">
            <div className="mb-4">
              <h1>Hello,</h1>
              <h2>Enter your details</h2>
            </div>

            <div>
              <div className="form-control">
                <input
                  type="text"
                  name="name"
                  className="form-input"
                  placeholder="full name"
                  required
                  autoComplete="off"
                  value={inputs.name}
                  onChange={handleInputs}
                />
                <label htmlFor="name" className="form-label input-label">
                  full name
                </label>
              </div>
              <div className="form-control my-3">
                <input
                  type="text"
                  name="email"
                  className="form-input"
                  placeholder="email address"
                  required
                  autoComplete="off"
                  value={inputs.email}
                  onChange={handleInputs}
                />
                <label htmlFor="name" className="form-label input-label">
                  Email address
                </label>
              </div>
              <div className="form-control">
                <input
                  type="tel"
                  name="mobile"
                  className="form-input"
                  placeholder="mobile"
                  required
                  autoComplete="off"
                  value={inputs.mobile}
                  onChange={handleInputs}
                />
                <label htmlFor="mobile" className="form-label input-label">
                  mobile number
                </label>
              </div>
              <div className="mt-3">
                {message && (
                  <p
                    className={`${
                      error ? "text-danger" : "text-success"
                    } fw-bold`}
                  >
                    {message}
                  </p>
                )}
                <div className="text-center">
                  <button
                    className="white-button w-100"
                    style={{
                      justifyContent: loading ? "center" : "space-between",
                    }}
                    onClick={signup}
                  >
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      <>
                        Signup
                        <FaArrowRight className="ms-2" />
                      </>
                    )}
                  </button>
                </div>
              </div>
              <div className="text-center mt-3">
                <p>
                  Already have an account?{" "}
                  <Link to="/login" className="primary-text fw-bold">
                    Login
                  </Link>
                </p>
              </div>

              <div className="store-flex mt-3">
                <a href="https://apps.apple.com/in/app/singledebt/id6480590793">
                  <img src={Apple} alt="" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.singledebt&hl=en_IN">
                  <img src={Google} alt="" />
                </a>
              </div>

              <ul className="tc-links mt-4">
                <li>
                  <Link to="/termsconditions">Terms & Conditions</Link>
                </li>{" "}
                |
                <li>
                  <Link
                    to="https://singledebt.in/privacy-policy"
                    target="_blank"
                  >
                    Privacy Policy
                  </Link>
                </li>{" "}
                |
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup2;
