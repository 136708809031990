import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import colorLogo from "../assets/images/color logo.png";
import {
  FaHome,
  FaJournalWhills,
  FaPowerOff,
  FaTicketAlt,
  FaQuestion,
  FaWhatsapp,
} from "react-icons/fa";
import { CgNotes } from "react-icons/cg";
import { LiaRupeeSignSolid } from "react-icons/lia";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import { FaRegCopy } from "react-icons/fa";

const AppBar = () => {
  const { url, user, getToken } = useContext(AppContext);
  //
  const [logoutModal, setLogoutModal] = useState(false);
  const closeLogoutModal = () => {
    setLogoutModal(false);
    setShowLink(false);
  };
  const navigate = useNavigate();
  const logout = () => {
    localStorage.removeItem("sdUser");
    navigate("/login", { replace: true });
  };
  //
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    name: "",
    mobile: "",
  });
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setInputs({
      ...inputs,
      [name]: value,
    });
  };
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  //
  const [referLink, setReferLink] = useState("");
  const [showLink, setShowLink] = useState(false);
  //
  const [referModal, setReferModal] = useState(false);
  const [referState, setReferState] = useState(1);
  const postReference = async () => {
    let referCount = 1;
    const key = `Refer_${referCount}`;
    console.log(user[key]);
    return;
    if (!inputs.name || !inputs.mobile) {
      setError(true);
      setMessage("Enter complete details");
    } else {
      try {
        setLoading(true);
        const token = await getToken();
        const data = [
          {
            Refer_1: inputs.name,
            Refer_Phone1: inputs.mobile,
            id: user.id,
          },
        ];
        const res = await axios.put(
          `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads`,
          data,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Zoho-oauthtoken ${token}`,
            },
          }
        );
        if (res.status === 201) {
          setError(false);
          setMessage("Message sent.");
          setTimeout(() => {
            setInputs({
              name: "",
              mobile: "",
            });
            setReferLink(
              `http://localhost:3000/signup?referral_name=${user.First_Name}_${user.Last_Name}&referral_number=${inputs.mobile}`
            );
            setShowLink(true);
            setMessage("");
          }, 2000);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  //
  useMemo(() => {
    if (message !== "") {
      setTimeout(() => {
        setMessage("");
      }, 3000);
    }
  }, [message]);
  return (
    <>
      <div className="container-fluid bg-white p-2 sticky-top d-flex align-items-center justify-content-between">
        <div className="logo">
          <Link to="/" onClick={() => window.scrollTo(0, 0)}>
            <img src={colorLogo} alt="logo" loading="lazy" />
          </Link>
        </div>
        <div className="d-flex align-items-center justify-content-between gap-2">
          <Link to="/help" className="button d-sm-block d-none">
            Help & Support
          </Link>
          <Link to="/help" className="button d-sm-none d-block">
            <FaQuestion />
          </Link>
          <button className="button" onClick={() => setLogoutModal(true)}>
            <FaPowerOff />
          </button>
        </div>
      </div>
      <div className="container p-2">
        <div
          className="notice-board p-2 mt-2"
          onClick={() => setReferModal(true)}
          style={{ cursor: "pointer" }}
        >
          <marquee behavior="" direction="left">
            <p className="text-white">
              <span className="fw-bold">Recommend a friend or family </span>
              <span className="me-2">
                and Earn extra income, if you know someone who is being harassed
                or struggling with their debts
              </span>
              <span className="fw-bold">
                <LiaRupeeSignSolid /> 1,000 for anyone who enrols with us. Click
                here.{" "}
              </span>
            </p>
          </marquee>
        </div>

        <div className="mt-4">
          <h2 className="text-md-center">Welcome back {user.Full_Name}</h2>
          <p className="text-capitalize fw-bold">
            Account manager: {user.Account_Manager.name}
          </p>
          <p className="text-capitalize fw-bold">
            Unique reference number:{" "}
            <span className="text-uppercase">{user.Lead_Unique_ID}</span>
          </p>
        </div>

        <ul className="tab mt-4 list-unstyled">
          <li className="active">
            <NavLink to="/" className="white-button">
              <FaHome />
              Home
            </NavLink>
          </li>
          <li>
            <NavLink to="/todo" className="white-button">
              <CgNotes />
              <span>To-Do list</span>
            </NavLink>
          </li>
          <li>
            <NavLink to="/journal" className="white-button">
              <FaJournalWhills />
              Journal
            </NavLink>
          </li>
          <li>
            <a
              href={user.Lead_Activity_Details}
              target="_blank"
              rel="noreferrer"
              className="white-button"
            >
              <FaJournalWhills />
              Activity logs
            </a>
          </li>
          <li>
            <NavLink to="/ticket" className="white-button">
              <FaTicketAlt />
              Tickets
            </NavLink>
          </li>
        </ul>
      </div>

      {/* modal */}
      <div className={`custom-modal-container ${logoutModal ? "active" : ""}`}>
        <div
          className="custom-modal-background"
          onClick={() => setLogoutModal(false)}
        ></div>
        <div className="custom-modal-box">
          <div className="body py-4 p-2 text-center rounded">
            <h2>Logout?</h2>
            <div className="d-flex align-items-center justify-content-center gap-2 mt-2">
              <button className="button" onClick={logout}>
                Yes
              </button>
              <button className="button" onClick={closeLogoutModal}>
                No
              </button>
            </div>
          </div>
        </div>
      </div>
      {/*  */}
      <div className={`custom-modal-container ${referModal ? "active" : ""}`}>
        <div
          className="custom-modal-background"
          onClick={() => setReferModal(false)}
        ></div>
        <div className="custom-modal-box">
          <div className={`body p-2 rounded`}>
            {showLink ? (
              <>
                <h2 className="mb-4" style={{ textTransform: "unset" }}>
                  Share below link with the refered person
                </h2>
                <p>{referLink}</p>
                <div className="d-flex  align-items-center justify-content-end gap-2 mt-2">
                  <button className="white-button refer-button">
                    <FaRegCopy />
                  </button>
                  <button className="white-button refer-button">
                    <FaWhatsapp />
                  </button>
                  <button
                    className="white-button"
                    style={{ height: "40px" }}
                    onClick={() => setReferModal(false)}
                  >
                    Close
                  </button>
                </div>
              </>
            ) : (
              <>
                <h2 className="mb-4" style={{ textTransform: "unset" }}>
                  Refer a friend or a family member
                </h2>
                <div className="mb-4">
                  <label>Name of friend or family</label>
                  <input
                    type="text"
                    className="form-control border-bottom"
                    name="name"
                    value={inputs.name}
                    onChange={handleInputs}
                    autoComplete="off"
                  />
                </div>
                <div>
                  <label>Mobile number</label>
                  <input
                    type="number"
                    className="form-control border-bottom"
                    name="mobile"
                    value={inputs.mobile}
                    onChange={handleInputs}
                    autoComplete="off"
                  />
                </div>
                <p
                  className={`${
                    error ? "text-danger" : "text-success"
                  } text-end my-2`}
                >
                  {message}
                </p>
                <div className="d-flex  align-items-center justify-content-end gap-2 mt-2">
                  <button
                    className="white-button"
                    onClick={() => setReferModal(false)}
                  >
                    Close
                  </button>
                  <button className="button" onClick={postReference}>
                    {loading ? (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      >
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default AppBar;
