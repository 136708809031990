import React, { useContext, useEffect, useState } from "react";
import { FaRupeeSign } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import Assistance from "../Assistance";
import { description } from "../../assets/data/enroll";
import { AppContext } from "../../context/AppContext";
import axios from "axios";

const Plans = ({ step, setStep, inputs }) => {
  const { url, getAccountStatus, getToken } = useContext(AppContext);
  const [loading, setLoading] = useState(false);
  const [planLoading, setPlanLoading] = useState(false);
  const [recommended, setRecommended] = useState(null);
  const [plans, setPlans] = useState([
    {
      months: 12,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 18,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 24,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 36,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 48,
      emi: 0,
      subscription: 0,
      total: 0,
    },
    {
      months: 60,
      emi: 0,
      subscription: 0,
      total: 0,
    },
  ]);
  const [id, setId] = useState("");
  useEffect(() => {
    const restructure = async () => {
      setLoading(true);
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      const result = await getAccountStatus(sdUser);
      setId(result.id);
      if (result.id !== 0) {
        const calculateDI =
          parseFloat(result.Income) - parseFloat(result.Expenses);
        //
        const updatedPlans = plans.map((item, index) => {
          const emi = parseFloat(result.Outstanding) / parseFloat(item.months);

          const subscription = (parseFloat(emi) * 30) / 100;
          const total = parseFloat(emi) + parseFloat(subscription);

          return {
            ...item,
            index,
            emi: parseFloat(emi.toFixed(0)),
            subscription: parseFloat(subscription.toFixed(0)),
            total: parseFloat(total.toFixed(0)),
          };
        });
        setPlans(updatedPlans);
        //
        const eligiblePlans = updatedPlans.filter(
          (item) => item.total < calculateDI && item.total > 9999
        );
        if (eligiblePlans.length > 0) {
          const closestPlan = eligiblePlans.reduce((prev, curr) =>
            Math.abs(curr.total - calculateDI) <
            Math.abs(prev.total - calculateDI)
              ? curr
              : prev
          );
          setRecommended(closestPlan.index);
        } else {
          setRecommended(null);
        }
        //
        setTimeout(() => {
          setLoading(false);
        }, 2000);
      } else {
        setStep(0);
      }
    };
    restructure();
  }, []);

  //
  const [tempLoad, setTempLoad] = useState(null);
  const selectPlan = async (selectedPlan, index) => {
    try {
      const token = await getToken();
      setTempLoad(index);
      setPlanLoading(true);
      const data = [
        {
          Plan_Type: `${selectedPlan.months} months`,
          Monthly_EMI_Payment: selectedPlan.emi.toString(),
          Total_Plan_Amount: selectedPlan.total.toString(),
          Subscription_Fees: selectedPlan.subscription.toString(),
          Step: 2,
        },
      ];
      //
      const res = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setStep(step + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setPlanLoading(false);
    }
  };
  return (
    <>
      <Assistance
        body={
          "After reviewing all the information, your tailored solutions are below"
        }
      />
      <div className="assistance-seperator"></div>
      <div className="description pb-4">
        <h1 className="text-center primary-text">
          Harassment and debt solutions
        </h1>
        <div className="description-grid mt-4">
          {description.slice(0, 2).map((item, index) => {
            return (
              <div
                className={`description-card description-card-${index + 1}`}
                key={index}
              >
                <div className="description-img">
                  <img src={item.img} alt="" />
                </div>
                <div>
                  <h2 className="primary-text">{item.title}</h2>
                  <p>{item.body}</p>
                  {index === 2 ? (
                    <button className="button description-button">
                      Read more
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div>
        <h1 className="primary-text text-center mb-4">
          Select your affordable plan below
        </h1>
        {loading ? (
          <div className="text-center my-4">
            <div
              className="spinner-border spinner-border-xxl pink-loader"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        ) : (
          <>
            <Swiper
              spaceBetween={8}
              slidesPerView={1}
              pagination={{ clickable: true }}
              navigation
              modules={[Navigation]}
              initialSlide={0}
              loop
              breakpoints={{
                200: {
                  slidesPerView: 1,
                },
                768: {
                  slidesPerView: 2,
                },
                992: {
                  slidesPerView: 3,
                },
              }}
            >
              {plans.map((item, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div
                      className={`plan-card ${
                        item.emi < 9999
                          ? "justify-content-center"
                          : "justify-content-between"
                      }`}
                    >
                      {item.emi > 9999 && recommended === index ? (
                        <div className="recommended">
                          <p>Recommended</p>
                        </div>
                      ) : (
                        ""
                      )}

                      {item.emi < 9999 ? (
                        <p>
                          You are not eligible for this plan because your EMI is
                          coming out to be less than 10,000.
                        </p>
                      ) : (
                        <>
                          <div>
                            <div className="mb-4">
                              <h2>{item.months} months plan</h2>
                            </div>
                            <h1>
                              <FaRupeeSign />
                              {item.total.toLocaleString("en-IN")}
                            </h1>
                            <h2 className="fw-bold">Total monthly EMI</h2>
                          </div>
                          <div className="plan-flex">
                            <h2 className="fw-bold">One Time Fee</h2>
                            <h2>
                              <FaRupeeSign />
                              599
                            </h2>
                          </div>
                        </>
                      )}
                      {item.emi < 9999 ? (
                        ""
                      ) : (
                        <button
                          className={`button plan-button ${
                            item.emi < 9999 ? "disabled-button" : ""
                          }`}
                          onClick={() => selectPlan(item, index)}
                          style={{ minWidth: "65px" }}
                          id={`plan${index + 1}`}
                        >
                          {tempLoad === index && planLoading ? (
                            <div className="text-center">
                              <div
                                className="spinner-border spinner-border-sm pink-loader"
                                role="status"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>
                            </div>
                          ) : (
                            "Select"
                          )}
                        </button>
                      )}
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </>
        )}
      </div>

      <div className="golden-box">
        <h1 className="text-center">
          Our one time fee <FaRupeeSign />
          599{" "}
        </h1>
        <div>
          {description[2].body.map((item, index) => {
            return (
              <div
                className={`${
                  index === description[2].body.length - 1 ? "" : "mb-2"
                } goldren-flex`}
                key={index}
              >
                <img src={item.img} alt="" />
                <p className="fw-bold">{item.content}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="text-center mt-4">
        <button
          className="button"
          onClick={() => setStep(step - 1)}
          id="back-to-form2"
        >
          Back
        </button>
      </div>
    </>
  );
};

export default Plans;
