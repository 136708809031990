import React, { useContext, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { FaPhoneAlt, FaRupeeSign } from "react-icons/fa";
import Assistance from "../Assistance";
import axios from "axios";

const EnrollForm1 = ({
  step,
  setStep,
  inputs,
  handleInputs,
  errors,
  setErrors,
}) => {
  const { url, preventChange, getAccountStatus, getToken } =
    useContext(AppContext);
  //
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  //
  const validate = () => {
    const {
      No_Of_Loans,
      Outstanding,
      EMI_Payments,
      Missed_Payment,
      Harassment_Type,
      Legal_Status,
      Income,
      Expenses,
    } = inputs;

    const newErrors = {};

    if (!Outstanding) {
      newErrors.Outstanding = "Enter your total unsecured debts";
    } else if (parseFloat(Outstanding) < 120000) {
      setLessOSModal(true);
    } else if (isNaN(Outstanding)) {
      newErrors.Outstanding = "Please enter a valid number for outstanding";
    } else if (!Number.isInteger(Number(Outstanding))) {
      newErrors.Outstanding =
        "Decimals are not allowed for total unsecured debts";
    }
    if (!No_Of_Loans) {
      newErrors.No_Of_Loans = "Enter your number of unsecured debts";
    } else if (isNaN(No_Of_Loans)) {
      newErrors.No_Of_Loans = "Please enter a valid number for debts";
    } else if (!Number.isInteger(Number(No_Of_Loans))) {
      newErrors.No_Of_Loans = "Decimals are not allowed for debt number";
    }
    if (!EMI_Payments) {
      newErrors.EMI_Payments = "Enter your total unsecured EMI";
    } else if (isNaN(EMI_Payments)) {
      newErrors.EMI_Payments = "Please enter a valid number for EMI";
    } else if (!Number.isInteger(Number(EMI_Payments))) {
      newErrors.EMI_Payments = "Decimals are not allowed for EMI amount";
    }
    if (!Harassment_Type) {
      newErrors.Harassment_Type = "Select the type of harassment";
    }
    if (!Missed_Payment) {
      newErrors.Missed_Payment = "This field is mandatory";
    }
    if (!Legal_Status) {
      newErrors.Legal_Status = "Select the type of legal action";
    }

    if (!Income) {
      newErrors.Income = "Enter your total Income";
    } else if (isNaN(Income)) {
      newErrors.Income = "Enter a valid number";
    } else if (!Number.isInteger(Number(Income))) {
      newErrors.Income = "Decimals are not allowed for Income";
    }
    if (!Expenses) {
      newErrors.Expenses = "Enter your total Expenses";
    } else if (isNaN(Expenses)) {
      newErrors.Expenses = "Enter a valid number";
    } else if (!Number.isInteger(Number(Expenses))) {
      newErrors.Expenses = "Decimals are not allowed for Expenses";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      setModalVisible(true);
    }
  };
  //
  const submit = async () => {
    try {
      const sdUser = JSON.parse(localStorage.getItem("sdUser"));
      setLoading(true);
      setError(false);
      setMessage("Please hold on. This might take a minute or two.");
      const retryGetAccountStatus = async (
        attempt = 1,
        maxAttempts = 20,
        delay = 5000
      ) => {
        const result = await getAccountStatus(sdUser);

        if (result.id) {
          handleSubmit(result);
          setLoading(false);
          setError(false);
        } else if (attempt < maxAttempts) {
          setTimeout(
            () => retryGetAccountStatus(attempt + 1, maxAttempts, delay),
            delay
          );
        } else {
          setMessage(
            "Failed to retrieve account status after several attempts. Please try again later."
          );
        }
      };

      await retryGetAccountStatus();
    } catch (error) {
      console.log(error);
    }
  };
  //
  const handleSubmit = async (result) => {
    setLoading(true);
    const {
      No_Of_Loans,
      Outstanding,
      EMI_Payments,
      Missed_Payment,
      Harassment_Type,
      Legal_Status,
      Income,
      Expenses,
    } = inputs;
    const data = [
      {
        No_Of_Loans,
        Outstanding,
        EMI_Payments,
        Missed_Payment,
        Harassment_Type,
        Legal_Status,
        Income,
        Expenses,
        Step: 1,
      },
    ];
    try {
      const token = await getToken();
      const res = await axios.put(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/${result.id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );
      if (res.status === 200) {
        setStep(step + 1);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      setMessage("");
    }
    //
  };
  //
  const [modalVisible, setModalVisible] = useState(false);
  const [lessOSModal, setLessOSModal] = useState(false);
  return (
    <>
      <Assistance
        body={
          "Please share details about your debts and actions being taken by creditors."
        }
      />
      <div className="assistance-seperator"></div>
      <div className="enroll-div enroll-form-1">
        <h1 className="primary-text text-center">
          Your unsecure debts and type of harassment
        </h1>
        <div className="mt-4">
          <div className="mb-3">
            <div className="form-control">
              <input
                type="number"
                name="Outstanding"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.Outstanding}
                onChange={handleInputs}
                onKeyDown={preventChange}
                min="1"
              />
              <label htmlFor="Outstanding" className="form-label input-label">
                Total unsecured debts (Rs)
              </label>
            </div>
            <p className="text-danger">{errors.Outstanding}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <input
                type="number"
                name="No_Of_Loans"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.No_Of_Loans}
                onChange={handleInputs}
                onKeyDown={preventChange}
                min="1"
              />
              <label htmlFor="No_Of_Loans" className="form-label input-label">
                Number of unsecured debts
              </label>
            </div>
            <p className="text-danger">{errors.No_Of_Loans}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <input
                type="number"
                name="EMI_Payments"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.EMI_Payments}
                onChange={handleInputs}
                onKeyDown={preventChange}
                min="1"
              />
              <label htmlFor="EMI_Payments" className="form-label input-label">
                Total EMI of unsecured debts (Rs)
              </label>
            </div>
            <p className="text-danger">{errors.EMI_Payments}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <input
                type="tel"
                name="Income"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.Income}
                onChange={handleInputs}
              />
              <label htmlFor="Income" className="form-label input-label">
                Enter your total Income
              </label>
            </div>
            <p className="text-danger">{errors.Income}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <input
                type="tel"
                name="Expenses"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.Expenses}
                onChange={handleInputs}
              />
              <label htmlFor="Expenses" className="form-label input-label">
                Enter your total Expenses
              </label>
            </div>
            <p className="text-danger">{errors.Expenses}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <select
                name="Missed_Payment"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.Missed_Payment}
                onChange={handleInputs}
              >
                <option disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <label
                htmlFor="Missed_Payment"
                className="form-label input-label"
              >
                Missed an EMI in last 3 months?
              </label>
            </div>
            <p className="text-danger">{errors.Missed_Payment}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <select
                name="Harassment_Type"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.Harassment_Type}
                onChange={handleInputs}
              >
                <option disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <label
                htmlFor="Harassment_Type"
                className="form-label input-label"
              >
                Experiencing any harassment?
              </label>
            </div>
            <p className="text-danger">{errors.Harassment_Type}</p>
          </div>
          <div className="mb-3">
            <div className="form-control">
              <select
                name="Legal_Status"
                className="form-input"
                required
                autoComplete="off"
                value={inputs.Legal_Status}
                onChange={handleInputs}
              >
                <option disabled></option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <label htmlFor="Legal_Status" className="form-label input-label">
                Experiencing any legal action?
              </label>
            </div>
            <p className="text-danger">{errors.Legal_Status}</p>
          </div>
        </div>
        <div className="text-center">
          <button className="button" onClick={validate}>
            Proceed
          </button>
        </div>
      </div>

      {/*  */}
      <div className={`custom-modal-container ${modalVisible ? "active" : ""}`}>
        <div
          className="custom-modal-background"
          onClick={() => setModalVisible(false)}
        ></div>
        <div className="custom-modal-box">
          <div className="header p-2">
            <p className="fw-bold text-capitalize">
              Your unsecure debts and type of harassment
            </p>
          </div>
          <div className="body p-2">
            <p>
              Total unsecured debts:{" "}
              <span className="fw-bold">
                {" "}
                <FaRupeeSign />
                {inputs.Outstanding}
              </span>
            </p>
            <p>
              Number of unsecured debts:{" "}
              <span className="fw-bold">{inputs.No_Of_Loans}</span>
            </p>
            <p>
              Total EMI:{" "}
              <span className="fw-bold">
                <FaRupeeSign />
                {inputs.EMI_Payments}
              </span>
            </p>
            <p>
              Have you missed EMI in last 3 months?:{" "}
              <span className="fw-bold">{inputs.Missed_Payment}</span>
            </p>
            <p>
              Experiencing any harassment?:{" "}
              <span className="fw-bold">{inputs.Harassment_Type}</span>
            </p>
            <p>
              Experiencing any legal action?:{" "}
              <span className="fw-bold">{inputs.Legal_Status}</span>
            </p>
            <p>
              Total Income:{" "}
              <span className="fw-bold">
                <FaRupeeSign />
                {inputs.Income}
              </span>
            </p>
            <p>
              Total expenses:{" "}
              <span className="fw-bold">
                <FaRupeeSign />
                {inputs.Expenses}
              </span>
            </p>
          </div>
          <div className="footer text-end p-2">
            <p>{message}</p>
            <div className="modal-button-flex">
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={() => setModalVisible(false)}
              >
                Close
              </button>
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={submit}
                id="form-1-button"
              >
                {loading ? (
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  <>Confirm</>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/*  */}
      <div className={`custom-modal-container ${lessOSModal ? "active" : ""}`}>
        <div
          className="custom-modal-background"
          onClick={() => setLessOSModal(false)}
        ></div>
        <div className="custom-modal-box">
          <div className="body rounded text-center py-4 p-2">
            <p>
              Your outstanding amount is less; please reach out to our financial
              advisor. <br />
              <br />
              <a href="tel:02268762605" className="primary-text fw-bold">
                {" "}
                <FaPhoneAlt /> 02268762605
              </a>
            </p>
            <div className="modal-button-flex justify-content-center mt-4">
              <button
                className="button text-center"
                style={{ minWidth: "80px" }}
                onClick={() => setLessOSModal(false)}
              >
                Close
              </button>
              <a
                href="tel:02268762605"
                className="button text-center d-block"
                id="form-1-button"
                style={{ minWidth: "80px" }}
              >
                Call
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EnrollForm1;
