import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import axios from "axios";
import AppBar from "../components/AppBar";
import Loading from "../components/Loading";

const Auth = ({ Component }) => {
  const { url, user, setUser, getToken } = useContext(AppContext);
  const navigate = useNavigate();
  const location = useLocation();
  const hideAppBarRoutes = ["/enroll", "/thankyou", "/creditors"];

  const [error, setError] = useState(null);
  const [errorStatus, setErrorStatus] = useState(false);

  const authenticate = async () => {
    const sdUser = JSON.parse(localStorage.getItem("sdUser"));

    if (!sdUser) {
      navigate("/login", { replace: true });
      return;
    }

    try {
      let token = await getToken();
      if (!token) {
        const createToken = await axios.get(`${url}/token/generate`);
        token = createToken.data.token[0].token;
      }

      const res = await axios.get(
        `${url}/proxy?url=https://www.zohoapis.in/crm/v2/Leads/search?criteria=((Phone_Number:equals:${sdUser}))`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Zoho-oauthtoken ${token}`,
          },
        }
      );

      if (res.status === 200 && res.data?.data?.length > 0) {
        const details = res.data.data[0];

        // Handle account status
        if (!details.Account_Status || details.Account_Status === "Inactive") {
          navigate("/login", { replace: true });
          return;
        } else {
          Object.keys(details).forEach((key) => {
            if (details[key] === null) {
              details[key] = "";
            }
          });
          setUser(details);
          if (details.Account_Status === "Enrolled") {
            navigate("/enroll", { replace: true });
            return;
          } else if (details.Account_Status === "Active") {
            navigate("/", { replace: true });
            return;
          }
        }
      } else {
        setError(
          res.status === 204 ? "Account not found" : "Authentication failed."
        );
        setErrorStatus(res.status === 204);
      }
    } catch (error) {
      console.error(error);
      setError("Token expired. Please try again later.");
    }
  };

  useEffect(() => {
    authenticate();
  }, []); // Run once on mount

  const differentAccount = () => {
    localStorage.removeItem("sdUser");
    navigate("/login", { replace: true });
  };

  if (error) {
    return (
      <div className="d-flex flex-column align-items-center justify-content-between gap-2 py-4">
        <p>{error}</p>
        {errorStatus && (
          <button className="button" onClick={differentAccount}>
            Login with different account
          </button>
        )}
      </div>
    );
  }

  return user !== null ? (
    <>
      {!hideAppBarRoutes.includes(location.pathname) && <AppBar />}
      <Component />
    </>
  ) : (
    <Loading />
  );
};

export default Auth;
